import moment from 'moment'

import { APITYPES } from 'types/apitypes'
import { useGetTimezoneFromLocalstorage } from 'App/hooks'
import { formatDateTime } from 'utils/formatDateTime'
import { Recipient } from 'types'

import { getColumns } from './getColumns'


enum Status {
  'awaiting_sending' = 'Ожидает отправки',
  'sent' = 'Доставлено',
  'canceled' = 'Отменено',
  'active' = 'Активная',
  'inactive' = 'Не активная'
}

export const getRows = (mails: APITYPES.MailingType[]) => {
  const timezone = useGetTimezoneFromLocalstorage()

  return mails.map((mail) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'recipients') {
        row[column.dataIndex] = mail.recipients.length
      } else if (column.dataIndex === 'state_name') {
        const status = mail.state_name

        row[column.dataIndex] = Status[status]
      } else if (column.dataIndex === 'created') {
        const date = moment(mail[column.dataIndex]).format('DD.MM.YYYY')
        const time = moment(mail[column.dataIndex]).format('HH:mm:ss Z')

        const shift = formatDateTime({
          start: time,
          targetTimezone: timezone,
        })

        row[column.dataIndex] = `${date}, ${shift}`
      } else if (column.dataIndex === 'start_time') {
        const date = moment(mail[column.dataIndex]).format('DD.MM.YYYY')
        const time = moment(mail[column.dataIndex]).format('HH:mm:ss Z')

        const shift = formatDateTime({
          start: time,
          targetTimezone: timezone,
        })

        row[column.dataIndex] = `${date}, ${shift}`
      } else {
        //@ts-ignore
        row[column.dataIndex] = mail[column.dataIndex]
      }
    })
    row.key = mail.id
    row.mail = mail

    return row
  })
}

const transformRecipientsToRow = (recipient: Recipient) => {
  const status = recipient.state_name

  return {
    key: recipient.id,
    state_name: Status[status],
    name: recipient.name,
    description: recipient.description,
  }
}

export function getRecipients(mail?: APITYPES.MailingType) {
  return mail?.recipients?.map((recipient) => transformRecipientsToRow(recipient)) ?? []
}