import React, { useEffect, useState } from 'react'
import { Button, DatePicker } from 'antd'
import { useDispatch } from 'react-redux'
import { useLocation, useHistory } from 'react-router-dom';
import { ROUTES } from 'config/routes'
import { fetchRentByPeriod, sendToBilling } from 'AC/rent/rent'

import { getPickerStateFromSearch, M, getFormattedDates } from './utils'
import styles from './styles.module.sass'
import { action, Actions } from '../../../../../actions';

type Props = {
  hasBillingData: boolean
}

export const RentHeader = ({ hasBillingData }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()

  const [picker, setPicker] = useState<[M, M] | undefined>(getPickerStateFromSearch(location.search) as [M, M] | undefined)
  const onPickerChange = (dates: [M, M]) => {
    if (!dates) {
      history.push(ROUTES.PARTNERS.RENT.path)
      dispatch(action(Actions.SET_RENT_DATA, null))
      return
    }
    const dateParams = getFormattedDates(dates);

    history.push(ROUTES.PARTNERS.RENT.PARAMS.createPath(dateParams))
  }

  useEffect(() => {
    setPicker(getPickerStateFromSearch(location.search) as [M, M] | undefined)
  }, [location.search])


  const onUpdate = () => {
    if (!picker) return
    const dates = getFormattedDates(picker);
    dispatch(fetchRentByPeriod(dates))
  }

  const onSendToBilling = () => {
    if (!picker) return
    const dates = getFormattedDates(picker);
    dispatch(sendToBilling(dates))
  }

  return (
    <div className={styles.container}>
      {/*@ts-ignore*/}
      <DatePicker.RangePicker value={picker} onChange={onPickerChange} format={'L'}/>
      <Button disabled={!picker} onClick={onUpdate}>Расчитать аренду</Button>
      <Button disabled={!hasBillingData} onClick={onSendToBilling}>Закрыть период</Button>
    </div>
  );
};
