export enum StatusNames {
  new = 'new',
  active = 'active',
  inactive = 'inactive',
  disconnected = 'disconnected',
}

export const getTranslateCarwashStatus = (status: string) => {
  if (status === 'new') {return 'новая'}

  if (status === 'active') {return 'активна'}

  if (status === 'inactive') { return 'неактивна' }

  if (status === 'disconnected') {return 'отключена'}

  return 'новая'
}

export const getTranslatePartnerStatus = (status: string) => {
  if (status === 'new') {return 'новый'}

  if (status === 'active') {return 'активный'}

  if (status === 'disconnected') {return 'отключен'}

  return 'новый'
}

export const getColorByStatus = (status: string) => {
  if (status === 'new') {
    return 'blue'
  }

  if (status === 'active') {
    return 'green'
  }

  if (status === 'inactive') {
    return 'red'
  }

  if (status === 'disconnected') {
    return 'gray'
  }

  return 'blue'
}