import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export const getMailing = (params?: APITYPES.Mailing.Get.Req): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('getMailing', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Mailing.Get.URL, {
        params: {
          ...params,
          offset: getState().mailingReducer.mailing?.length ?? 0,
          limit: 20,
        },
      })

      if (resp) {
        dispatch(action(Actions.SET_MAILING, {
          mailing: resp,
          hasMoreMailing: Boolean(resp.length),
        }))

        dispatch(changeRequestState('getMailing', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('getMailing', StatusRequest.ERROR))

      showError(e.response.data.description)

      throw(e)
    }
  }
}

export const addMailing = (body: APITYPES.Mailing.POST.Req, onFinish?: () => void): ThAction => {
  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('addMailing', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Mailing.POST.URL, body)

      if (resp) {
        onFinish?.()
        dispatch(action(Actions.CLEAN_MAILS, {}))
        dispatch(getMailing())
        dispatch(changeRequestState('addMailing', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('addMailing', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}
