import React, { useState } from 'react'
import { Button, Menu, Tag , Dropdown } from 'antd'
import { useDispatch } from 'react-redux'
import { DownOutlined } from '@ant-design/icons'

import { showSureConfirm } from 'components/modals/SureModal'
import { activateCarwash } from 'AC/carwashes/carwashes'
import { APITYPES } from 'types/apitypes'
import { getTranslateCarwashStatus, getColorByStatus } from 'utils/tagUtils'
import { Deactivate } from '../Carwash/Deactivate'
import { Off } from '../Carwash/Off'
import styles from '../styles.module.sass'


type Props = {
  value: APITYPES.Carwash
}

export const Status = ({ value }: Props) => {
  const onClick = (e: any) => {
    e.stopPropagation()
  }
  const menu = (
    <Menu>
      <Menu.Item key='0'>
        <Buttons card={value} />
      </Menu.Item>
    </Menu>
  )

  
  return (
    <div onClick={onClick}>
      <Dropdown
        overlay={menu}
        trigger={['click']}
      >
        <a
          className='ant-dropdown-link'
          onClick={(e) => e.preventDefault()}
        >
          <Tag
            color={getColorByStatus(value.state.name)}
          >
            { getTranslateCarwashStatus(value.state.name) }
          </Tag>
          { ' ' }
          <DownOutlined />
        </a>
      </Dropdown>
    </div>
  )
}

const Buttons = ({ card }: { card: APITYPES.Carwash }) => {
  const dispatch = useDispatch()
  
  const [isOffModal, setIsOffModal] = useState(false)
  
  const [isDeactivateModal, setIsDeactivateModal] = useState(false)
  
  
  const onActivate = async (e: any) => {
    e.stopPropagation()
    showSureConfirm(() => { dispatch(activateCarwash(card.id)) }, () => { })
  }
  
  const value = card.state.name
  
  const modals = (
    <span onClick={(e: any)=>e.stopPropagation()}>
      <Off
        onCancel={() => setIsOffModal(false)}
        isVisible={isOffModal}
        id={card.id}
        key={card.id}
      />
      <Deactivate
        onCancel={() => setIsDeactivateModal(false)}
        isVisible={isDeactivateModal}
        id={card.id}
        key={card.id}
      />
    </span>
  )
  
  if (value === 'disconnected') {
    return (
      <div className={styles.buttons}>
        { modals }
        <Button
          className={styles.button}
          type='text'
          onClick={onActivate}
          key='1'
        >
          <Tag
            color={getColorByStatus('active')}
          >
            { getTranslateCarwashStatus('active') }
          </Tag>
        </Button>
        <Button
          className={styles.button}
          type='text'
          onClick={
            (e: any) => {
              e.stopPropagation()
              setIsDeactivateModal(true)
            }
          }
          key='2'
        >
          <Tag
            color={getColorByStatus('inactive')}
          >
            { getTranslateCarwashStatus('inactive') }
          </Tag>
        </Button>
      </div>
    )
  }

  if (value === 'new') {
    return (
      <div className={styles.buttons}>
        { modals }
        <Button
          className={styles.button}
          type='text'
          onClick={onActivate}
          key='1'
        >
          <Tag
            color={getColorByStatus('active')}
          >
            { getTranslateCarwashStatus('active') }
          </Tag>
        </Button>
        <Button
          className={styles.button}
          type='text'
          onClick={
            (e: any) => {
              e.stopPropagation()
              setIsDeactivateModal(true)
            }
          }
          key='1'
        >
          <Tag
            color={getColorByStatus('inactive')}
          >
            { getTranslateCarwashStatus('inactive') }
          </Tag>
        </Button>
        <Button
          className={styles.button}
          type='text'
          onClick={
            (e: any) => {
              e.stopPropagation()
              setIsOffModal(true)
            }
          }
          key='2'
        >
          <Tag
            color={getColorByStatus('disconnected')}
          >
            { getTranslateCarwashStatus('disconnected') }
          </Tag>
        </Button>
      </div>
    )
  }
  
  if (value === 'active') {
    return (
      <div className={styles.buttons}>
        { modals }
        <Button
          className={styles.button}
          type='text'
          onClick={
            (e: any) => {
              e.stopPropagation()
              setIsDeactivateModal(true)
            }
          }
          key='1'
        >
          <Tag
            color={getColorByStatus('inactive')}
          >
            { getTranslateCarwashStatus('inactive') }
          </Tag>
        </Button>
        <Button
          className={styles.button}
          type='text'
          onClick={
            (e: any) => {
              e.stopPropagation()
              setIsOffModal(true)
            }
          }
          key='2'
        >
          <Tag
            color={getColorByStatus('disconnected')}
          >
            { getTranslateCarwashStatus('disconnected') }
          </Tag>
        </Button>
      </div>
    )
  }
  
  return (
    <div className={styles.buttons}>
      { modals }
      <Button
        className={styles.button}
        type='text'
        onClick={onActivate}
        key='1'
      >
        <Tag
          color={getColorByStatus('active')}
        >
          { getTranslateCarwashStatus('active') }
        </Tag>
      </Button>
      <Button
        className={styles.button}
        type='text'
        onClick={
          (e: any) => {
            e.stopPropagation()
            setIsOffModal(true)
          }
        }
        key='2'
      >
        <Tag
          color={getColorByStatus('disconnected')}
        >
          { getTranslateCarwashStatus('disconnected') }
        </Tag>
      </Button>
    </div>
  )
}