import React, { useState } from 'react'
import { Modal, Form, Input, Button, Checkbox, DatePicker, Collapse, Card, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { createCarwash } from 'AC/carwashes/carwashes'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { FormFooter } from 'components/FormFooter'
import { AREAS, DISTRICTS, SUBWAYS, qosDictionary } from '../../config'

import styles from './styles.module.sass'


const { Panel } = Collapse

type Props = {
  isVisible: boolean
  onCancel: () => void
}

const dateFormat = 'YYYY-MM-DD'
const viewDateFormat = 'DD-MM-YYYY'

const selector = (state: State) => {
  const { allPartners } = state.partnersReducer
  const { partnerIdForCreating } = state.carwashesReducer

  return {
    partners: allPartners,
    partnerIdForCreating,
  }
}

export const CreateCard = (props: Props) => {
  const { partners, partnerIdForCreating } = useSelector(selector)

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const [ employees, setEmployees ] = useState<APITYPES.CarwashEmployeeBase[]>([])
      
  const { isVisible, onCancel } = props

  const dispatch = useDispatch()

  const [ currentEmployee, setCurrentEmployee ] = useState<APITYPES.CarwashEmployeeBase>({
    name: '',
    role: '',
    phones: [],
    emails: [],
    comment: '',
  })

  const [form] = useForm()

  const onAddEmployee = async () => {
    setEmployees((value) => ([...value, currentEmployee]))
  }

  const onFinish = async (values: any) => {
    dispatch(createCarwash({
      max_load_time: values.max_load_time,
      working_time: values.working_time,
      rent_cost: (values.rent_cost && values.rent_unit) ? +values.rent_cost : undefined,
      rent_unit: (values.rent_cost && values.rent_unit) ? values.rent_unit : undefined,
      partner_id: values.partner_id,
      name: values.name,
      qos: parseFloat(values.qos),
      object_photos: values.object_photos,
      own_type: values.own_type,
      cooperation_type: values.cooperation_type,
      questionnare: values.questionnare,
      object_type: values.object_type,
      layout: values.layout,
      bq_id: parseFloat(values.bq_id),
      space_quantity: parseFloat(values.space_quantity),
      gate_height: parseFloat(values.gate_height),
      tire_service: Boolean(values.tire_service),
      chemical_cleaning: Boolean(values.chemical_cleaning),
      client_zone: Boolean(values.client_zone),
      vending_machine: Boolean(values.vending_machine),
      cameras: Boolean(values.cameras),
      cashless_payment: Boolean(values.cashless_payment),
      fuel_card: Boolean(values.fuel_card),
      parking: Boolean(values.parking),
      cooperation_date: values.cooperation_date ? values.cooperation_date.format(dateFormat) : null,
      location: {
        region: values.region,
        area: values.area,
        district: values.district,
        address: values.address,
        subway: values.subway,
        directions: values.directions,
        longitude: values.longitude,
        latitude: values.latitude,
        located: values.located,
        closed_area: Boolean(values.closed_area),
      },
      employees: employees,
    }))
    form.resetFields()
    onCancel()
  }

  const onChangeCurrentEmployee = (_: any, values: any) => {
    setCurrentEmployee({
      phones: values.phones ? values.phones.split(' ') : [],
      emails: values.emails ? values.emails.split(' ') : [],
      name: values.name ?? '',
      role: values.role ?? '',
      comment: values.comment ?? '',
    })
  }

  return (
    <Modal
      className={styles.modal}
      title='Создание '
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name='time_related_controls'
        form={form}
        {...formItemLayout}
        onFinish={onFinish}
      >
        <Form.Item
          name='name'
          label='Имя автомойки'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='qos'
          label='Качество обслуживания'
        >
          <Select>
            {
              Array.from(qosDictionary.keys()).map((key)=>{
                return (
                  <Select.Option
                    key={key}
                    value={key}
                  >
                    { qosDictionary.get(key) }
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          name='bq_id'
          label='id в прошлой системе'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='partner_id'
          label='Партнер'
          initialValue={partnerIdForCreating}
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={
              (input, option: any) => {
                return option.children.toLocaleLowerCase().includes(input)
              }
            }
          >
            {
              partners.filter((partner) => partner.state.name !== 'disconnected').map((item, i)=>{
                return (
                  <Select.Option
                    key={i}
                    value={item.id}
                  >
                    { item.legal_name }
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <Form.Item
          name='object_photos'
          label='Фотографии объекта'
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='own_type'
          label='Способ владения'
          initialValue='Аренда'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select showSearch>
            <Select.Option value='Аренда'>Аренда</Select.Option>
            <Select.Option value='Собственность'>Собственность</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='cooperation_type'
          label='Формат сотрудничества'
          initialValue='Подряд'
        >
          <Select showSearch>
            <Select.Option value='Подряд'>Подряд</Select.Option>
            <Select.Option value='Аренда'>Аренда</Select.Option>
            <Select.Option value='Гибридный'>Гибридный</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='questionnare'
          label='Ссылка на анкету'
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='object_type'
          label='Тип объекта'
          initialValue='Мойка'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select showSearch>
            <Select.Option value='Мойка'>Мойка</Select.Option>
            <Select.Option value='Шиномонтаж'>Шиномонтаж</Select.Option>
            <Select.Option value='Автосервис'>Автосервис</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='layout'
          label='Планировка'
          initialValue='Боксы'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select showSearch>
            <Select.Option value='Боксы'>Боксы</Select.Option>
            <Select.Option value='Туннельная'>Туннельная</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name='space_quantity'
          label='Кол-во боксов (машиномест для туннеля)'
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          initialValue={false}
          name='tire_service'
          valuePropName='checked'
          wrapperCol={
            {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }
          }
        >
          <Checkbox>Шиномонтаж</Checkbox>
        </Form.Item>
        <Form.Item
          name='chemical_cleaning'
          initialValue={false}
          valuePropName='checked'
          wrapperCol={
            {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }
          }
        >
          <Checkbox>Химчистка</Checkbox>
        </Form.Item>
        <Form.Item
          name='client_zone'
          valuePropName='checked'
          wrapperCol={
            {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }
          }
          initialValue={false}
        >
          <Checkbox>Клиентская зона</Checkbox>
        </Form.Item>
        <Form.Item
          name='vending_machine'
          initialValue={false}
          valuePropName='checked'
          wrapperCol={
            {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }
          }
        >
          <Checkbox>Вендиногвый аппарат</Checkbox>
        </Form.Item>
        <Form.Item
          name='cameras'
          initialValue={false}
          valuePropName='checked'
          wrapperCol={
            {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }
          }
        >
          <Checkbox>Камера контроля мойки автомобиля</Checkbox>
        </Form.Item>
        <Form.Item
          name='cashless_payment'
          initialValue={false}
          valuePropName='checked'
          wrapperCol={
            {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }
          }
        >
          <Checkbox>Безналичная оплсата услуг</Checkbox>
        </Form.Item>
        <Form.Item
          name='fuel_card'
          initialValue={false}
          valuePropName='checked'
          wrapperCol={
            {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }
          }
        >
          <Checkbox>Работа с топливными картами</Checkbox>
        </Form.Item>
        <Form.Item
          name='parking'
          initialValue={false}
          valuePropName='checked'
          wrapperCol={
            {
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 16,
                offset: 8,
              },
            }
          }
        >
          <Checkbox>Наличие парковки</Checkbox>
        </Form.Item>
        <Form.Item
          name='gate_height'
          label='Высота ворот, м'
        >
          <Input type='number' />
        </Form.Item>
        <Form.Item
          name='max_load_time'
          label='Пиковое время загрузки'
        >
          <Input />
        </Form.Item>
        <Form.Item
          name='working_time'
          label='График работы'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Input />
        </Form.Item>

        <Form.Item label="Стоимость аренды">
          <Input.Group compact>
            <Form.Item name="rent_cost" noStyle>
              <Input type='number' style={{width: '60%'}}/>
            </Form.Item>

            <Form.Item name="rent_unit" noStyle>
              <Select defaultValue={undefined} style={{width: '40%'}} allowClear>
                <Select.Option value="day">Сутки</Select.Option>
                <Select.Option value="month">Месяц</Select.Option>
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          label='Дата начала сотрудничества'
          name='cooperation_date'
        >
          <DatePicker format={viewDateFormat} />
        </Form.Item>
        <Collapse
          className={styles.collapse}
          defaultActiveKey='1'
        >
          <Panel
            header='Расположение'
            key='1'
          >
            <Form.Item
              name='region'
              label='Регион'
              initialValue='Москва'
              rules={
                [{
                  required: true,
                  message: 'Поле должно быть заполнено',
                }]
              }
            >
              <Select showSearch>
                <Select.Option value='Москва'>Москва</Select.Option>
                <Select.Option value='Санкт-Петербург'>Санкт-Петербург</Select.Option>
                <Select.Option value='Сочи'>Сочи</Select.Option>
                <Select.Option value='Краснодар'>Краснодар</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              name='area'
              label='Округ'
              initialValue={AREAS[0]}
            >
              <Select showSearch>
                {
                  AREAS.map((area, i)=>{
                    return (
                      <Select.Option
                        key={i}
                        value={area}
                      >
                        { area }
                      </Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name='district'
              label='Район'
              initialValue={DISTRICTS[0]}
            >
              <Select showSearch>
                {
                  DISTRICTS.map((item, i)=>{
                    return (
                      <Select.Option
                        key={i}
                        value={item}
                      >
                        { item }
                      </Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name='address'
              label='Адрес'
              rules={
                [{
                  required: true,
                  message: 'Поле должно быть заполнено',
                }]
              }
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='subway'
              label='Метро'
              initialValue={SUBWAYS[0]}
            >
              <Select showSearch>
                {
                  SUBWAYS.map((item, i)=>{
                    return (
                      <Select.Option
                        key={i}
                        value={item}
                      >
                        { item }
                      </Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
            <Form.Item
              name='directions'
              label='Как проехать'
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='longitude'
              label='Долгота'
              rules={
                [{
                  required: true,
                  message: 'Поле должно быть заполнено',
                }]
              }
            >
              <Input
                type='number'
                step={0.000001}
              />
            </Form.Item>
            <Form.Item
              name='latitude'
              label='Широта'
              rules={
                [{
                  required: true,
                  message: 'Поле должно быть заполнено',
                }]
              }
            >
              <Input
                type='number'
                step={0.000001}
              />
            </Form.Item>
            <Form.Item
              initialValue={false}
              name='closed_area'
              valuePropName='checked'
              wrapperCol={
                {
                  xs: {
                    span: 24,
                    offset: 0,
                  },
                  sm: {
                    span: 16,
                    offset: 8,
                  },
                }
              }
            >
              <Checkbox>На закрытой территории (есть шлагбаум)</Checkbox>
            </Form.Item>
            <Form.Item
              name='located'
              label='Расположение мойки'
              initialValue='Отдельностоящая'
            >
              <Select showSearch>
                <Select.Option value='Отдельностоящая'>Отдельностоящая</Select.Option>
                <Select.Option value='В паркинге'>В паркинге</Select.Option>
              </Select>
            </Form.Item>
          </Panel>
          <Panel
            header='Сотрудники'
            key='2'
          >
            <Form
              name='employeesForm'
              onValuesChange={onChangeCurrentEmployee}
            >
              <Form.Item
                name='name'
                label='ФИО'
                rules={
                  [{
                    required: true,
                    message: 'Поле должно быть заполнено',
                  }]
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='role'
                label='Должность'
                rules={
                  [{
                    required: true,
                    message: 'Поле должно быть заполнено',
                  }]
                }
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='phones'
                label='Телефоны (пишите через пробел)'
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='emails'
                label='Почты (пишите через пробел)'
              >
                <Input />
              </Form.Item>
              <Form.Item
                name='comment'
                label='Комментарий'
              >
                <Input />
              </Form.Item>
              <Button
                type='primary'
                onClick={onAddEmployee}
              >
                Добавить работника
              </Button>
              {
                employees.map((item, i) => {
                  return (
                    <Card
                      key={i}
                      size='small'
                      title={item.name}
                      style={
                        {
                          width: 300,
                          marginTop: 20,
                        }
                      }
                    >
                      <p>{ item.role }</p>
                    </Card>
                  )
                })
              }
            </Form>
          </Panel>
        </Collapse>
        <FormFooter
          onCancel={onCancel}
          position='right'
        />
      </Form>
    </Modal>
  )
}