import React, { useEffect } from 'react'
import { Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { APITYPES } from 'types/apitypes'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchCarwashes } from 'AC/carwashes/carwashes'
import { addCustomerTariffLocations } from 'AC/products/tariffs'
import { getEnumOptions } from 'utils/enumUtils'
import { useDebouncedSearch } from 'utils/hooks/useDebouncedSearch'
import { FormFooter } from 'components/FormFooter'

const {Cities} = APITYPES
const cities = getEnumOptions(Cities)

const formLayout = {
  labelCol: {span: 4},
}

type Props = {
  onClose: () => void
}

const selector = (state: State) => ({
  tariff: state.customerTariffsReducer.customerTariff,
  searchedCarwashes: state.carwashesReducer.searchedCarwashes,
  loading: state.requestsReducer.fetchCarwashes === StatusRequest.LOADING
})

export const LocationForm = ({onClose}: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const {tariff, searchedCarwashes, loading} = useSelector(selector)

  const cityValue = Form.useWatch('city_id', form);
  const carwashValue = Form.useWatch('carwash_id', form);

  useEffect(() => {
    return () => {form.resetFields()}
  }, [])

  const [search, setSearch] = useDebouncedSearch({defaultValue: '', disableOnEmpty: true},
    () => dispatch(fetchCarwashes({search: search}, 12, 0, true)),
  )

  const onFinish = (values: APITYPES.Tariffs.Location.POST.Req) => {
    if (!tariff) return
    dispatch(addCustomerTariffLocations(tariff.id, {
      carwash_id: values?.carwash_id,
      city_id: values?.city_id ? values?.city_id : undefined
    }))
    onClose()
  }

  return (
    <Form form={form} onFinish={onFinish} {...formLayout}>
      <Form.Item label="Город" name="city_id">
        <Select placeholder="Выберите город" allowClear disabled={Boolean(carwashValue)}>
          {cities.map(city => (<Select.Option key={city.value} value={city.value}>{city.label}</Select.Option>))}
        </Select>
      </Form.Item>

      <div style={{margin: '-17px 0 7px 42px'}}>или</div>

      <Form.Item label="Мойка" name="carwash_id">
        <Select
          allowClear
          showSearch
          loading={loading}
          searchValue={search}
          filterOption={false}
          disabled={Boolean(cityValue)}
          placeholder="Начните вписывать адрес мойки"
          onSearch={(value) => setSearch(value)}
        >
          {searchedCarwashes.map(wash => (<Select.Option key={wash.id} value={wash.id}>{wash.address}</Select.Option>))}
        </Select>
      </Form.Item>

      <FormFooter onCancel={onClose} okText="Добавить"/>
    </Form>
  );
};
