import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Input, Button, Table } from 'antd'
import qs from 'qs'

import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchCarwashes } from 'AC/carwashes/carwashes'
import { fetchPartners } from 'AC/partners/partners'
import { fetchClientTypes } from 'AC/directory/directory'
import { InfiniteTable } from 'components/InfiniteTable'
import { Loader } from 'components/Loader'
import { isObjectEmpty } from 'utils/filterUtils'
import { useDebounce } from 'utils/debounce'
import { navigate } from 'utils/tabelUtils'
import { CreateCard } from './Card/CreateCard'
import { FilterIcon } from 'components/FilterIcon'
import { exportCarwashesToDocument } from 'AC/partners/exportPartnersToDocument'

import { Filters } from './Filters'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'


const { Search } = Input

const selector = (state: State) => {
  const { carwashes, hasMore, isCreating, filter } = state.carwashesReducer
  const { fetchCarwashes, exportCarwashes } = state.requestsReducer
  const { allPartners } = state.partnersReducer
  const { clientTypes } = state.directoryReducer

  return {
    isLoading: fetchCarwashes === StatusRequest.LOADING,
    isExportLoading: exportCarwashes === StatusRequest.LOADING,
    hasMore,
    carwashes,
    filter,
    clientTypes,
    isStoreCreating: isCreating,
    partners: allPartners,
  }
}

export const Carwashes = () => {
  const dispatch = useDispatch()
  const params = Object.fromEntries(new URL(window.location.href).searchParams.entries())
  const history = useHistory()
  const {
    isLoading,
    isExportLoading,
    isStoreCreating,
    hasMore,
    carwashes,
    filter,
    partners,
    clientTypes,
  } = useSelector(selector)

  const [filterOpen, setFilterOpen] = useState<boolean>(false)
  const [isCreate, setIsCreate] = useState(false)
  const debouncedSearchTerm = useDebounce(filter.search, 1000)

  const toggleFilter = () => setFilterOpen((prev) => !prev)

  const loadMore = () => {
    dispatch(fetchCarwashes({
      search: params.search,
      partner_id: params.partner_id?.length ? params.partner_id?.split(',') : undefined,
      state: params.state?.length ? params.state?.split(',') : undefined,
      client_type: params.client_type?.length ? params.client_type?.split(',') : undefined,
      region: params.region?.length ? params.region?.split(',') : undefined,
    }))
  }

  const onSearch = (value: string) => {
    const state = value.length ? value : undefined

    dispatch(action(Actions.CLEAN_CARWASHES, {}))
    dispatch(fetchCarwashes({ search: state }))
  }

  const onSearchChange = (event: any) => {
    if (event.target.value === '') {
      dispatch(
        action(Actions.SET_CARWASHES_FILTER, {
          ...filter,
          search: undefined,
        }),
      )

      dispatch(action(Actions.CLEAN_CARWASHES, {}))
      dispatch(fetchCarwashes({}))

      history.push(ROUTES.CARWASHES.PARAMS.createPath({
        ...filter,
        search: '',
      }))
    }

    dispatch(
      action(Actions.SET_CARWASHES_FILTER, {
        ...filter,
        search: event.target.value,
      }),
    )
  }

  const exportToTable = () => {
    dispatch(exportCarwashesToDocument(filter))
  }

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      const parsed = qs.parse(params)

      dispatch(action(Actions.SET_CARWASHES_FILTER, {
        ...parsed,
        client_type: params.client_type?.length ? params.client_type?.split(',') : undefined,
        state: params.state?.length ? params.state?.split(',') : undefined,
      }))
    } else if (!isObjectEmpty(filter)) {
      history.push(ROUTES.CARWASHES.PARAMS.createPath(filter))
    }
  }, [dispatch])

  useEffect(() => {
    if (debouncedSearchTerm && filter.search?.trim().length) {
      history.push(ROUTES.CARWASHES.PARAMS.createPath(filter))
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
      dispatch(fetchCarwashes(filter, 20, 0))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm])

  useEffect(() => {
    dispatch(action(Actions.SET_CARWASH, undefined))
    if (!clientTypes) {dispatch(fetchClientTypes())}
    if (!partners.length) {
      dispatch(fetchPartners({
        limit: 1000,
        all: true,
      }))
    }
  }, [dispatch])

  if (!carwashes) {
    return <Loader />
  }

  return (
    <>
      <div className={styles.main}>
        <Search
          value={filter.search}
          onChange={onSearchChange}
          placeholder='Поиск моек'
          onSearch={onSearch}
          enterButton
        />
        <div className={styles.toolbar}>
          <div className={styles.toolbarActions}>
            <Button
              type='primary'
              onClick={() => setIsCreate(true)}
            >
              Добавить мойку
            </Button>
            <Button
              type='primary'
              disabled={isExportLoading}
              onClick={exportToTable}
            >
              Экспорт в таблицу
            </Button>
          </div>


          <Button
            size='small'
            onClick={toggleFilter}
            icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
          />
        </div>
        <CreateCard
          isVisible={isCreate || isStoreCreating}
          onCancel={
            () => {
              dispatch(action(Actions.SET_IS_CARWASH_CREATING, false))
              setIsCreate(false)
            }
          }
        />
        {
          clientTypes && (
            <InfiniteTable
              isLoading={isLoading}
              hasMore={hasMore}
              columns={
                getColumns()
              }
              loadMore={loadMore}
              className={styles.tableContainer}
            >
              <Table
                showHeader={false}
                columns={getColumns() as any}
                dataSource={getRows(carwashes)}
                className={styles.table}
                bordered
                size='large'
                pagination={false}
                scroll={{ x: '100%' }}
                onRow={(record: any) => ({ onClick: navigate(ROUTES.CARWASHES.ID.createPath(record.key), history) })}
              />
            </InfiniteTable>
          )
        }
      </div>

      <Filters
        isOpen={filterOpen}
        onClose={toggleFilter}
      />
    </>
  )
}
