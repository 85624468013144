import { ThunkAction } from 'redux-thunk'
import { useDispatch } from 'react-redux'

import { Api } from 'network/http'
import { State } from 'stores/rootReducer'
import { RequestsState, StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { Mailing } from 'types'
import { DoerRecordsFilter } from './stores/doerRecordsReducer'
import { PlacementToUpdate } from './stores/yandexProReducer'
import { InternshipsFilters } from './stores/internships-reducer'


export type AE<T> = { type: T };
export type A<T, D> = {
  type: T
  data: D
};

export namespace Actions {
  export const SET_TARIFF_ERROR = 'SET_TARIFF_ERROR' as const
  export type SetTariffError = A<typeof SET_TARIFF_ERROR,
  {
    serviceId: string
    carClassId: string
  } | null>;

  export const SET_PARTNER_DOCUMENTS = 'SET_PARTNER_DOCUMENTS' as const
  export type SetPartnerDocuments = A<typeof SET_PARTNER_DOCUMENTS,
  APITYPES.PartnerDocument[]>;

  export const ADD_PARTNER_DOCUMENT = 'ADD_PARTNER_DOCUMENT' as const
  export type AddPartnerDocument = A<typeof ADD_PARTNER_DOCUMENT,
  APITYPES.PartnerDocument>;

  export const UPDATE_PARTNER_DOCUMENT = 'UPDATE_PARTNER_DOCUMENT' as const
  export type UpdatePartnerDocument = A<typeof UPDATE_PARTNER_DOCUMENT,
  APITYPES.PartnerDocument>;

  export const DELETE_PARTNER_DOCUMENT = 'DELETE_PARTNER_DOCUMENT' as const
  export type DeletePartnerDocument = A<typeof DELETE_PARTNER_DOCUMENT, string>;

  export const SET_CARWASHES_FILTER = 'SET_CARWASHES_FILTER' as const
  export type SetCarwashesFilter = A<typeof SET_CARWASHES_FILTER,
  {
    state?: string[]
    region?: string[]
    partner_id?: string[]
    search?: string
  }>;

  export const SET_SEARCHED_CARWHASHES = 'SET_SEARCHED_CARWHASHES' as const
  export type SetSearchedCarwashes = A<typeof SET_SEARCHED_CARWHASHES,
  APITYPES.Carwashes.Get.Resp>;

  export const SET_CARWASHES_SEARCH = 'SET_CARWASHES_SEARCH' as const
  export type SetCarwashesSearch = A<typeof SET_CARWASHES_SEARCH, string>;

  export const SET_USERS_SEARCH = 'SET_USERS_SEARCH' as const
  export type SetUsersSearch = A<typeof SET_USERS_SEARCH, string | undefined>;

  export const SET_PARTNERS_SEARCH = 'SET_PARTNERS_SEARCH' as const
  export type SetPartnersSearch = A<typeof SET_PARTNERS_SEARCH,
  string | undefined>;

  export const CLEAN_TARIFFS = 'CLEAN_TARIFFS' as const
  export type CleanTariffs = A<typeof CLEAN_TARIFFS, {}>;

  // directory
  export const SET_CLIENT_TYPES = 'SET_CLIENT_TYPES' as const
  export type SetClientTypes = A<typeof SET_CLIENT_TYPES,
  APITYPES.CarwashClientType[]>;

  export const UPDATE_CLIENT_TYPES = 'UPDATE_CLIENT_TYPES' as const
  export type UpdateClientTypes = A<typeof UPDATE_CLIENT_TYPES,
  { value: boolean, id: string }>;

  export const SET_CAR_CLASSES = 'SET_CAR_CLASSES' as const
  export type SetCarClasses = A<typeof SET_CAR_CLASSES,
  APITYPES.CarClasses.Get.Resp>;

  export const SET_CLIENT_TYPE_CAR_CLASSES =
    'SET_CLIENT_TYPE_CAR_CLASSES' as const
  export type SetClientTypeCarClasses = A<typeof SET_CLIENT_TYPE_CAR_CLASSES,
  APITYPES.CarClasses.Get.Resp>;

  export const ADD_CAR_CLASS = 'ADD_CAR_CLASS' as const
  export type AddCarClass = A<typeof ADD_CAR_CLASS,
  APITYPES.CarClasses.Get.Resp[number]>;

  export const SET_SERVICES = 'SET_SERVICES' as const
  export type SetServices = A<typeof SET_SERVICES, APITYPES.Services.Get.Resp>;

  export const SET_CLIENT_TYPE_SERVICES = 'SET_CLIENT_TYPE_SERVICES' as const
  export type SetClientTypeServices = A<typeof SET_CLIENT_TYPE_SERVICES,
  APITYPES.Services.Get.Resp>;

  export const UPDATE_CLIENT_TYPE_SERVICES =
    'UPDATE_CLIENT_TYPE_SERVICES' as const
  export type UpdateClientTypeServices = A<typeof UPDATE_CLIENT_TYPE_SERVICES,
  { position: number, id: string }>;

  export const UPDATE_CLIENT_TYPE_CAR_CLASSES =
    'UPDATE_CLIENT_TYPE_CAR_CLASSES' as const
  export type UpdateClientTypeCarClasses = A<typeof UPDATE_CLIENT_TYPE_CAR_CLASSES,
  { position: number, id: string }>;

  export const ADD_SERVICE = 'ADD_SERVICE' as const
  export type AddService = A<typeof ADD_SERVICE,
  APITYPES.AllServices.Get.Resp[number]>;

  export const SET_DIRECTORY_CLIENT_TYPES =
    'SET_DIRECTORY_CLIENT_TYPES' as const
  export type SetDirectoryClientTypes = A<typeof SET_DIRECTORY_CLIENT_TYPES,
  APITYPES.ClientTypes.Get.Resp>;

  export const UPDATE_DIRECTORY_CLIENT_TYPES =
    'UPDATE_DIRECTORY_CLIENT_TYPES' as const
  export type UpdateDirectoryClientTypes = A<typeof UPDATE_DIRECTORY_CLIENT_TYPES,
  APITYPES.ClientTypes.GetClientType.Resp>;

  export const DELETE_DIRECTORY_CLIENT_TYPES =
    'DELETE_DIRECTORY_CLIENT_TYPES' as const
  export type DeleteDirectoryClientTypes = A<typeof DELETE_DIRECTORY_CLIENT_TYPES,
  { id: string }>;

  export const SET_DIRECTORY_SERVICES = 'SET_DIRECTORY_SERVICES' as const
  export type SetDirectoryServices = A<typeof SET_DIRECTORY_SERVICES,
  APITYPES.AllServices.Get.Resp>;

  export const SET_DIRECTORY_SERVICE = 'SET_DIRECTORY_SERVICE' as const
  export type SetDirectoryService = A<typeof SET_DIRECTORY_SERVICE,
  APITYPES.AllServices.Get.Resp[number]>;

  export const UPDATE_DIRECTORY_SERVICES = 'UPDATE_DIRECTORY_SERVICES' as const
  export type UpdateDirectoryServices = A<typeof UPDATE_DIRECTORY_SERVICES,
  APITYPES.AllServices.Get.Resp[number]>;

  export const DELETE_DIRECTORY_SERVICE = 'DELETE_DIRECTORY_SERVICE' as const
  export type DeleteDirectoryService = A<typeof DELETE_DIRECTORY_SERVICE,
  { id: string }>;

  export const SET_DIRECTORY_CAR_CLASSES = 'SET_DIRECTORY_CAR_CLASSES' as const
  export type SetDirectoryCarClasses = A<typeof SET_DIRECTORY_CAR_CLASSES,
  APITYPES.AllCarClasses.Get.Resp>;

  export const CREATE_DIRECTORY_CAR_CLASSES =
    'CREATE_DIRECTORY_CAR_CLASSES' as const
  export type CreateDirectoryCarClasses = A<typeof CREATE_DIRECTORY_CAR_CLASSES,
  APITYPES.AllCarClasses.Post.Resp>;

  export const SET_DIRECTORY_CAR_CLASS = 'SET_DIRECTORY_CAR_CLASS' as const
  export type SetDirectoryCarClass = A<typeof SET_DIRECTORY_CAR_CLASS, string>;

  export const CREATE_DIRECTORY_SERVICE = 'CREATE_DIRECTORY_SERVICE' as const
  export type CreateDirectoryService = A<typeof CREATE_DIRECTORY_SERVICE,
  APITYPES.AllServices.Post.Resp>;

  export const CREATE_DIRECTORY_CLIENT_TYPES =
    'CREATE_DIRECTORY_CLIENT_TYPES' as const
  export type CreateDirectoryClientTypes = A<typeof CREATE_DIRECTORY_CLIENT_TYPES,
  APITYPES.ClientTypes.Post.Resp>;

  export const SET_DIRECTORY_CLIENT_TYPE = 'SET_DIRECTORY_CLIENT_TYPE' as const
  export type SetDirectoryClientType = A<typeof SET_DIRECTORY_CLIENT_TYPE,
  APITYPES.ClientTypes.Get.Resp[number]>;

  export const SET_ALL_CAR_BRANDS = 'SET_ALL_CAR_BRANDS' as const
  export type SetAllCarBrands = A<typeof SET_ALL_CAR_BRANDS,
  APITYPES.Cars.GetBrands.Resp>;

  export const SET_ALL_BRAND_MODELS = 'SET_ALL_BRAND_MODELS' as const
  export type SetAllBrandModels = A<typeof SET_ALL_BRAND_MODELS,
  APITYPES.Cars.GetModels.Resp>;

  export const CLEAR_BRAND_MODELS = 'CLEAR_BRAND_MODELS' as const
  export type ClearBrandModels = AE<typeof CLEAR_BRAND_MODELS>;

  export const SET_CAR_MODEL_INFO = 'SET_CAR_MODEL_INFO' as const
  export type SetCarModelInfo = A<typeof SET_CAR_MODEL_INFO,
  APITYPES.Cars.GetModelInfo.Resp>;

  export const SET_CAR_MODEL_CAR_CLASSES = 'SET_CAR_MODEL_CAR_CLASSES' as const
  export type SetCarModelCarClasses = A<typeof SET_CAR_MODEL_CAR_CLASSES,
  APITYPES.CarClass[]>;

  export const SET_TARIFFS = 'SET_TARIFFS' as const
  export type SetTariffs = A<typeof SET_TARIFFS,
  APITYPES.SupplierTariffService[]>;

  export const CREATE_TARIFF = 'CREATE_TARIFF' as const
  export type CreateTariff = A<typeof CREATE_TARIFF,
  APITYPES.SupplierTariffService>;

  export const DELETE_TARIFF = 'DELETE_TARIFF' as const
  export type DeleteTariff = A<typeof DELETE_TARIFF, string>;

  export const UPDATE_TARIFF = 'UPDATE_TARIFF' as const
  export type UpdateTariff = A<typeof UPDATE_TARIFF,
  APITYPES.SupplierTariffService>;

  export const UPDATE_CARWASH = 'UPDATE_CARWASH' as const
  export type UpdateCarwash = A<typeof UPDATE_CARWASH,
  { carwash: APITYPES.Carwash, id: string }>;

  export const ADD_CARWASH = 'ADD_CARWASH' as const
  export type AddCarwash = A<typeof ADD_CARWASH, APITYPES.Carwashes.Post.Resp>;

  export const SET_CARWASHES = 'SET_CARWASHES' as const
  export type SetCarwashes = A<typeof SET_CARWASHES,
  { carwashes: APITYPES.Carwashes.Get.Resp, offset?: number }>;

  export const SET_CARWASH = 'SET_CARWASH' as const
  export type SetCarwash = A<typeof SET_CARWASH,
  APITYPES.Carwashes.GetCarwash.Resp>;

  export const SET_IS_CARWASH_CREATING = 'SET_IS_CARWASH_CREATING' as const
  export type SetIsCarwashCreating = A<typeof SET_IS_CARWASH_CREATING, boolean>;

  export const SET_PARTNER_ID_FOR_CREATING =
    'SET_PARTNER_ID_FOR_CREATING' as const
  export type SetPartnerIdForCreating = A<typeof SET_PARTNER_ID_FOR_CREATING,
  string>;

  export const SET_PARTNER = 'SET_PARTNER' as const
  export type SetPartner = A<typeof SET_PARTNER, APITYPES.Partner>;

  export const SET_PARTNER_BY_INN_INFO = 'SET_PARTNER_BY_INN_INFO' as const
  export type SetPartnerByInnInfo = A<typeof SET_PARTNER_BY_INN_INFO,
  APITYPES.PartnerInfoByInn>;

  export const SET_PARTNER_BY_INN_FOR_CREATING =
    'SET_PARTNER_BY_INN_FOR_CREATING' as const
  export type SetPartnerByInnForCreating = A<typeof SET_PARTNER_BY_INN_FOR_CREATING,
  APITYPES.PartnerInfoByInn>;

  export const UPDATE_PARTNER = 'UPDATE_PARTNER' as const
  export type UpdatePartner = A<typeof UPDATE_PARTNER,
  { partner: APITYPES.Partner, id: string }>;

  export const ADD_PARTNER = 'ADD_PARTNER' as const
  export type AddPartner = A<typeof ADD_PARTNER, APITYPES.Partner>;

  export const SET_PARTNERS = 'SET_PARTNERS' as const
  export type SetPartners = A<typeof SET_PARTNERS, APITYPES.Partner[]>;

  export const SET_ALL_PARTNERS = 'SET_ALL_PARTNERS' as const
  export type SetAllPartners = A<typeof SET_ALL_PARTNERS, APITYPES.Partner[]>;

  export const CLEAN_PARTNERS = 'CLEAN_PARTNERS' as const
  export type CleanPartners = A<typeof CLEAN_PARTNERS, {}>;

  export const CLEAN_USERS = 'CLEAN_USERS' as const
  export type CleanUsers = A<typeof CLEAN_USERS, {}>;

  export const CLEAN_CARWASHES = 'CLEAN_CARWASHES' as const
  export type CleanCarwashes = A<typeof CLEAN_CARWASHES, {}>;

  export const SET_IS_USER_SIGNED_IN = 'SET_IS_USER_SIGNED_IN' as const
  export type SetIsUserSignedIn = A<typeof SET_IS_USER_SIGNED_IN, boolean>;

  export const SET_BRANDS = 'SET_BRANDS' as const
  export type SetBrands = A<typeof SET_BRANDS, APITYPES.Cars.GetBrands.Resp>;

  export const SET_BRAND = 'SET_BRAND' as const
  export type SetBrand = A<typeof SET_BRAND,
  APITYPES.Cars.GetBrands.Resp[number]>;

  export const SET_MODELS = 'SET_MODELS' as const
  export type SetModels = A<typeof SET_MODELS, APITYPES.Cars.GetModels.Resp>;

  export const SET_CAR_CLASS_CARS = 'SET_CAR_CLASS_CARS' as const
  export type SetCarClassCars = A<typeof SET_CAR_CLASS_CARS,
  APITYPES.AllCarClasses.GetCars.Resp>;
  export const SET_CARWASH_PHOTOS = 'SET_CARWASH_PHOTOS' as const
  export type SetCarwashPhotos = A<typeof SET_CARWASH_PHOTOS,
  APITYPES.Carwashes.GetPhotos.Resp>;

  export const SET_CARWASHES_STATISTICS = 'SET_CARWASHES_STATISTICS' as const
  export type SetCarwashesStatistics = A<typeof SET_CARWASHES_STATISTICS,
  APITYPES.Statistics.Carwashes.Resp>;

  export const SET_PARTNERS_STATISTICS = 'SET_PARTNERS_STATISTICS' as const
  export type SetPartnersStatistics = A<typeof SET_PARTNERS_STATISTICS,
  APITYPES.Statistics.Partners.Resp>;

  export const SET_OPERATIONS_STATISTICS = 'SET_OPERATIONS_STATISTICS' as const
  export type SetOperationsStatistics = A<typeof SET_OPERATIONS_STATISTICS,
  APITYPES.Statistics.Operations.POST.Resp>;

  export const CLEAR_OPERATIONS_STATISTICS  = 'CLEAR_OPERATIONS_STATISTICS' as const
  export type ClearOperationsStatistics = AE<typeof CLEAR_OPERATIONS_STATISTICS>;

  export const SET_USERS = 'SET_USERS' as const
  export type SetUsers = A<typeof SET_USERS, APITYPES.User[]>;

  export const SET_USER = 'SET_USER' as const
  export type SetUser = A<typeof SET_USER, APITYPES.ExpandedUser>;

  //doers
  export const CLEAN_DOERS = 'CLEAN_DOERS' as const
  export type CleanDoers = AE<typeof CLEAN_DOERS>;


  export const SET_DOERS = 'SET_DOERS' as const
  export type SetDoers = A<typeof SET_DOERS,
  {
    doers: APITYPES.Doers.Get.Resp
    hasMore: boolean
  }>;

  export const SET_SEARCHED_DOERS = 'SET_SEARCHED_DOERS' as const
  export type SetSearchedDoer = A<typeof SET_SEARCHED_DOERS,
  APITYPES.Doers.Get.Resp>;

  export const SET_SEARCHED_DOER = 'SET_SEARCHED_DOER' as const
  export type SetSearchedByIdDoer = A<typeof SET_SEARCHED_DOER,
  APITYPES.Doers.GetById.Resp>;

  export const SET_DOER = 'SET_DOER' as const
  export type SetDoer = A<typeof SET_DOER, APITYPES.Doers.GetById.Resp>;

  export const SET_DOER_SHIFTS = 'SET_DOER_SHIFTS' as const
  export type SetDoerShifts = A<typeof SET_DOER_SHIFTS,
  {
    doerShifts: APITYPES.Doers.GetShifts.Resp
    hasMoreDoerShifts: boolean
  }>;

  export const CLEAR_DOER_SHIFTS = 'CLEAR_DOER_SHIFTS' as const
  export type ClearDoerShifts = AE<typeof CLEAR_DOER_SHIFTS>;

  export const SET_DOER_RESERVES = 'SET_DOER_RESERVES' as const
  export type SetDoerReserves = A<typeof SET_DOER_RESERVES,
  {
    doerReserves: APITYPES.Doers.GetReserves.Resp
    hasMoreDoerReserves: boolean
  }>;

  export const CLEAR_DOER_RESERVES = 'CLEAR_DOER_RESERVES' as const
  export type ClearDoerReserves = AE<typeof CLEAR_DOER_RESERVES>;

  export const SET_DOERS_FILTER = 'SET_DOERS_FILTER' as const
  export type SetDoersFilter = A<typeof SET_DOERS_FILTER,
  APITYPES.DoersFilterType>;

  export const UPDATE_DOER = 'UPDATE_DOER' as const
  export type UpdateDoer = A<typeof UPDATE_DOER, APITYPES.Doer>;

  //billing
  export const SET_BILLS = 'SET_BILLS' as const
  export type SetBills = A<typeof SET_BILLS, { bills: APITYPES.Bill[], offset?: number }>;

  export const SET_BILLS_DOERS = 'SET_BILLS_DOERS' as const
  export type SetBillsDoers = A<typeof SET_BILLS_DOERS, { billsDoers: APITYPES.Bill[], offset?: number }>;

  export const SET_BILLS_FILTER = 'SET_BILLS_FILTER' as const
  export type SetBillsFilter = A<typeof SET_BILLS_FILTER,
  {
    state?: string[]
    contractor_id?: string[]
    start?: string
    end?: string
  }>;

  export const SET_BILLS_FILTER_DOERS = 'SET_BILLS_FILTER_DOERS' as const
  export type SetBillsDoersFilter = A<typeof SET_BILLS_FILTER_DOERS,
  {
    state?: string[]
    contractor_id?: string[]
    start?: string
    end?: string
  }>;
  export const SET_EXPORT_BILLS = 'SET_EXPORT_BILLS' as const
  export type SetExportBills = A<typeof SET_EXPORT_BILLS,   {
    state?: string[]
    contractor_id?: string[]
    start?: string
    end?: string
    nonzero?: boolean
  }>;

  export const SET_CONTRACTORS_FILTER = 'SET_CONTRACTORS_FILTER' as const
  export type SetContractorsFilter = A<typeof SET_CONTRACTORS_FILTER, { search?: string }>;

  export const CLEAN_BILLS = 'CLEAN_BILLS' as const
  export type CleanBills = AE<typeof CLEAN_BILLS>;

  export const CLEAN_BILLS_DOERS = 'CLEAN_BILLS_DOERS' as const
  export type CleanBillsDoers = AE<typeof CLEAN_BILLS_DOERS>;
  export const CLEAN_EXPORT_BILLS = 'CLEAN_EXPORT_BILLS' as const
  export type CleanExportBills = AE<typeof CLEAN_EXPORT_BILLS>;

  export const SET_BILL = 'SET_BILL' as const
  export type SetBill = A<typeof SET_BILL, APITYPES.Bill | null>;

  export const SET_CONTRACTORS = 'SET_CONTRACTORS' as const
  export type SetContractors = A<typeof SET_CONTRACTORS, APITYPES.Contractor[]>;

  export const SET_DOER_CONTRACTORS = 'SET_DOER_CONTRACTORS' as const
  export type SetDoerContractors = A<typeof SET_DOER_CONTRACTORS,
  APITYPES.Contractor[]>;

  export const SET_PARTNER_CONTRACTORS = 'SET_PARTNER_CONTRACTORS' as const
  export type SetPartnerContractors = A<typeof SET_PARTNER_CONTRACTORS,
  APITYPES.Contractor[]>;

  export const SET_CONTRACTOR_RECORDS = 'SET_CONTRACTOR_RECORDS' as const
  export type SetContractorRecords = A<typeof SET_CONTRACTOR_RECORDS, APITYPES.ContractorRecord[]>;

  export const CLEAN_CONTRACTORS = 'CLEAN_CONTRACTORS' as const
  export type CleanContractors = AE<typeof CLEAN_CONTRACTORS>;

  export const SET_CONTRACTOR = 'SET_CONTRACTOR' as const
  export type SetContractor = A<typeof SET_CONTRACTOR,
  APITYPES.Contractor | null>;

  export const RESET_DOER_RECORDS = 'RESET_DOER_RECORDS ' as const
  export type ResetDoersRecords = AE<typeof RESET_DOER_RECORDS>;


  export const SET_DOER_RECORDS = 'SET_DOER_RECORDS ' as const
  export type SetDoersRecords = A<typeof SET_DOER_RECORDS , {
    activeTab: string
    doerRecords: APITYPES.DoerRecords[]
    offset?: number
  }>;


  export const CLEAN_DOER_RECORDS = 'CLEAN_DOER_RECORDS' as const
  export type CleanDoersRecords = AE<typeof CLEAN_DOER_RECORDS>;

  export const SET_DOER_RECORDS_PERIOD = 'SET_DOER_RECORDS_PERIOD' as const
  export type SetDoersRecordsPeriod = A<typeof SET_DOER_RECORDS_PERIOD,
  { doerRecordsPeriod: APITYPES.Billing.GetDoerRecordsPeriod.Resp, offset?: number }>;

  export const CLEAR_DOER_RECORDS_PERIOD = 'CLEAR_DOER_RECORDS_PERIOD' as const
  export type ClearDoerRecordsPeriod = AE<typeof CLEAR_DOER_RECORDS_PERIOD>

  //schedules
  export const SET_SCHEDULES = 'SET_SCHEDULES' as const
  export type SetSchedules = A<typeof SET_SCHEDULES, APITYPES.Schedule[]>;

  export const ADD_SCHEDULE = 'ADD_SCHEDULE' as const
  export type AddSchedule = A<typeof ADD_SCHEDULE, APITYPES.Schedule>;

  export const DELETE_SCHEDULE = 'DELETE_SCHEDULE' as const
  export type DeleteSchedule = A<typeof DELETE_SCHEDULE, string>;

  export const SET_SCHEDULE = 'SET_SCHEDULE' as const
  export type SetSchedule = A<typeof SET_SCHEDULE, APITYPES.Schedule | null>;

  export const SET_SCHEDULE_INTERVALS_MAP =
    'SET_SCHEDULE_INTERVALS_MAP' as const
  export type SetScheduleIntervalsMap = A<typeof SET_SCHEDULE_INTERVALS_MAP,
  Map<any, any> | null>;

  export const ADD_SCHEDULE_INTERVAL = 'ADD_SCHEDULE_INTERVAL' as const
  export type AddScheduleInterval = A<typeof ADD_SCHEDULE_INTERVAL,
  APITYPES.ScheduleInterval>;

  export const UPDATE_SCHEDULE_INTERVAL = 'UPDATE_SCHEDULE_INTERVAL' as const
  export type UpdateScheduleInterval = A<typeof UPDATE_SCHEDULE_INTERVAL,
  APITYPES.ScheduleInterval>;

  export const DELETE_SCHEDULE_INTERVAL = 'DELETE_SCHEDULE_INTERVAL' as const
  export type DeleteScheduleInterval = A<typeof DELETE_SCHEDULE_INTERVAL,
  string>;

  export const CHANGE_REQUEST_STATUS = 'CHANGE_REQUEST_STATUS' as const
  export type ChangeRequestStatus = A<typeof CHANGE_REQUEST_STATUS,
  {
    requestName: keyof RequestsState
    status: StatusRequest
  }>;

  // penalties
  export const SET_PENALTY_TYPES = 'SET_PENALTY_TYPES' as const
  export type SetPenaltyTypes = A<typeof SET_PENALTY_TYPES,
  APITYPES.Penalty.Get.Resp>;

  export const SET_PENALTIES = 'SET_PENALTIES' as const
  export type SetPenalties = A<typeof SET_PENALTIES,
  { carwashPenalties: APITYPES.CarwashPenalty[], offset?: number }>;

  export const ADD_NEW_PENALTY = 'ADD_NEW_PENALTY' as const
  export type AddNewPenalty = A<typeof ADD_NEW_PENALTY,
  APITYPES.Penalty.Post.Resp>;

  export const UPDATE_PENALTY_STATUS = 'UPDATE_PENALTY_STATUS' as const
  export type UpdatePenaltyStatus = A<typeof UPDATE_PENALTY_STATUS,
  APITYPES.Penalty.UpdateStatus.Resp>;

  export const SET_PENALTIES_FILTER = 'SET_PENALTIES_FILTER' as const
  export type SetPenaltiesFilter = A<typeof SET_PENALTIES_FILTER,
  APITYPES.Carwashes.GetPenalties.Query>;

  export const CLEAN_PENALTIES = 'CLEAN_PENALTIES' as const
  export type CleanPenalties = AE<typeof CLEAN_PENALTIES>;


  //Compensations
  export const SET_COMPENSATION_TYPES = 'SET_COMPENSATION_TYPES ' as const
  export type SetCompensationTypes = A<typeof SET_COMPENSATION_TYPES,
  APITYPES.Compensation.Get.Resp>;

  export const SET_COMPENSATIONS = 'SET_COMPENSATIONS' as const
  export type SetCompensations = A<typeof SET_COMPENSATIONS,
  { carwashCompensations: APITYPES.CarwashCompensation[], offset?: number }>;

  export const ADD_NEW_COMPENSATION = 'ADD_NEW_COMPENSATION' as const
  export type addNewCompensation = A<typeof ADD_NEW_COMPENSATION,
  APITYPES.Compensation.Post.Resp>;

  export const SET_COMPENSATIONS_FILTER = 'SET_COMPENSATIONS_FILTER' as const
  export type SetCompensationsFilter = A<typeof SET_COMPENSATIONS_FILTER,
  APITYPES.Carwashes.GetCompensations.Query>;

  export const CLEAN_COMPENSATIONS = 'CLEAN_COMPENSATIONS' as const
  export type CleanCompensations = AE<typeof CLEAN_COMPENSATIONS>;

  //shifts
  export const SET_SHIFT_TYPES = 'SET_SHIFT_TYPES' as const
  export type SetShiftTypes = A<typeof SET_SHIFT_TYPES,
  APITYPES.Shifts.ShiftTypes.GET.Resp>;

  export const SET_SHIFTS = 'SET_SHIFTS' as const
  export type SetShifts = A<typeof SET_SHIFTS, APITYPES.Shifts.Shift.GET.Resp>;

  export const SET_SHIFT_TYPES_BY_ROLE = 'SET_SHIFT_TYPES_BY_ROLE' as const
  export type SetShiftTypesByRole = A<typeof SET_SHIFT_TYPES_BY_ROLE,
  APITYPES.ShiftTypesByRoles[]>;

  export const SET_SHIFT_TYPES_BY_ROLE_FILTER =
    'SET_SHIFT_TYPES_BY_ROLE_FILTER' as const
  export type SetShiftTypesByRoleFilter = A<typeof SET_SHIFT_TYPES_BY_ROLE_FILTER,
  string>;

  export const SET_SHIFT_TYPES_BY_ROLEID = 'SET_SHIFT_TYPES_BY_ROLEID' as const
  export type SetShiftTypesByRoleId = A<typeof SET_SHIFT_TYPES_BY_ROLEID,
  APITYPES.ShiftTypesByRolesId[]>;

  export const SET_SHIFT_TYPES_BY_ROLEID_FILTER =
    'SET_SHIFT_TYPES_BY_ROLEID_FILTER' as const
  export type SetShiftTypesByRoleIdFilter = A<typeof SET_SHIFT_TYPES_BY_ROLEID_FILTER,
  string>;

  export const SET_SHIFTS_FILTER = 'SET_SHIFTS_FILTER' as const
  export type SetShiftsFilter = A<typeof SET_SHIFTS_FILTER,
  APITYPES.ShiftFilter>;

  export const SET_SHIFT_DOERS = 'SET_SHIFT_DOERS' as const
  export type SetShiftDoers = A<typeof SET_SHIFT_DOERS, APITYPES.ShiftDoer[]>;

  export const CLEAR_SHIFT_DOERS = 'CLEAR_SHIFT_DOERS' as const
  export type ClearShiftDoers = AE<typeof CLEAR_SHIFT_DOERS>;

  export const DELETE_SHIFT = 'DELETE_SHIFT' as const
  export type DeleteShift = A<typeof DELETE_SHIFT, { shift_id: string }>;

  export const ADD_SHIFT_TAB = 'ADD_SHIFT_TAB' as const
  export type AddShiftTab = A<typeof ADD_SHIFT_TAB, APITYPES.Shift>;

  export const REMOVE_SHIFT_TAB = 'REMOVE_SHIFT_TAB' as const
  export type RemoveShiftTab = A<typeof REMOVE_SHIFT_TAB, { shift_id: string }>;

  export const SET_SHIFT_DOERS_FILTER = 'SET_SHIFT_DOERS_FILTER' as const
  export type SetShiftDoersFilter = A<typeof SET_SHIFT_DOERS_FILTER,
  APITYPES.ShiftDoersFilter>;

  export const SET_SHIFT_OFFERS = 'SET_SHIFT_OFFERS' as const
  export type SetShiftOffers = A<typeof SET_SHIFT_OFFERS,
  APITYPES.Offers.GetShiftOffers.Resp>;

  export const SET_SHIFT_OFFERS_REVERSE = 'SET_SHIFT_OFFERS_REVERSE' as const
  export type SetShiftOffersReverse = A<typeof SET_SHIFT_OFFERS_REVERSE,
  APITYPES.OfferReserve[]>;


  export const SET_SHIFTS_FOR_REDIRECT = 'SET_SHIFTS_FOR_REDIRECT' as const
  export type SetShiftsForRedirect = A<typeof SET_SHIFTS_FOR_REDIRECT,
  APITYPES.Shifts.Shift.GET.Resp>;

  // locations
  export const SET_STATIC_SHIFT_LOCATIONS =
    'SET_STATIC_SHIFT_LOCATIONS' as const
  export type SetStaticShiftLocations = A<typeof SET_STATIC_SHIFT_LOCATIONS,
  APITYPES.Shifts.ShiftLocations.GET.Resp>;

  export const ADD_STATIC_SHIFT_LOCATIONS =
      'ADD_STATIC_SHIFT_LOCATIONS' as const
  export type AddStaticShiftLocations = A<typeof ADD_STATIC_SHIFT_LOCATIONS,
  APITYPES.Shifts.ShiftLocations.GET.Resp>;

  export const SET_SHIFT_LOCATIONS = 'SET_SHIFT_LOCATIONS' as const
  export type SetShiftLocations = A<typeof SET_SHIFT_LOCATIONS,
  APITYPES.Shifts.ShiftLocations.GET.Resp>;

  export const ADD_SHIFT_LOCATION = 'ADD_SHIFT_LOCATION' as const
  export type AddShiftLocation = A<typeof ADD_SHIFT_LOCATION,
  APITYPES.Shifts.ShiftLocations.POST.Resp>;

  export const SET_LOCATIONS_FILTER = 'SET_LOCATIONS_FILTER' as const
  export type SetLocationsFilter = A<typeof SET_LOCATIONS_FILTER,
  APITYPES.Shifts.ShiftLocations.GET.Params>;

  export const SET_LOCATION_CHILDREN = 'SET_LOCATION_CHILDREN' as const
  export type SetLocationChildren = A<typeof SET_LOCATION_CHILDREN,
  APITYPES.Location[]>;

  export const SET_LOCATION_PHOTOS = 'SET_LOCATION_PHOTOS' as const
  export type SetLocationPhotos = A<typeof SET_LOCATION_PHOTOS, APITYPES.LocationFile[]>;

  // employers
  export const SET_EMPLOYERS = 'SET_EMPLOYERS' as const
  export type SetEmployers = A<typeof SET_EMPLOYERS, APITYPES.Shifts.Employer.GET.Resp>;

  export const SET_EMPLOYER = 'SET_EMPLOYER' as const
  export type SetEmployer = A<typeof SET_EMPLOYER, APITYPES.Employer>;

  // monitoring
  export const SET_DOER_MONITORING_ALLOCATIONS = 'SET_DOER_MONITORING_ALLOCATIONS' as const
  export type SetDoerMonitoringAllocations = A<typeof SET_DOER_MONITORING_ALLOCATIONS, {
    doerMonitoringAllocations: APITYPES.DoerMonitoring.GetAllocations.Resp
  }>

  export const SET_DOER_MONITORING_ALLOCATIONS_FILTER = 'SET_DOER_MONITORING_ALLOCATIONS_FILTER' as const
  // eslint-disable-next-line max-len
  export type SetDoerMonitoringAllocationsFilter = A<typeof SET_DOER_MONITORING_ALLOCATIONS_FILTER, APITYPES.DoerMonitoring.GetAllocations.Req>

  export const CLEAN_DOER_MONITORING_ALLOCATIONS  = 'CLEAN_DOER_MONITORING_ALLOCATIONS ' as const
  export type CleanDoerMonitoringAllocations = AE<typeof CLEAN_DOER_MONITORING_ALLOCATIONS>;

  // offers
  export const SET_OFFERS = 'SET_OFFERS' as const
  export type SetOffers = A<typeof SET_OFFERS,
  {
    offers: APITYPES.Offer[]
    hasMoreOffers: boolean
  }>;

  export const SET_RESERVE_OFFERS = 'SET_RESERVE_OFFERS' as const
  export type SetReserveOffers = A<typeof SET_RESERVE_OFFERS, APITYPES.Offer[]>;

  export const SET_OFFER = 'SET_OFFER' as const
  export type SetOffer = A<typeof SET_OFFER, APITYPES.Offers.GetOfferById.Resp>;

  export const CLEAR_OFFERS = 'CLEAR_OFFERS' as const
  export type ClearOffers = AE<typeof CLEAR_OFFERS>;

  export const SET_OFFERS_FILTER = 'SET_OFFERS_FILTER' as const
  export type SetOffersFilter = A<typeof SET_OFFERS_FILTER,
  APITYPES.Offers.GET.Query>;

  export const SET_OFFERS_RESERVES = 'SET_OFFERS_RESERVES' as const
  export type SetOfferReserves = A<typeof SET_OFFERS_RESERVES,
  APITYPES.Offers.SetShiftDoer.Resp>;

  //roles
  export const SET_ROLES = 'SET_ROLES' as const
  export type SetRoles = A<typeof SET_ROLES, APITYPES.Doers.Roles.GET.Resp>;

  export const CLEAN_ROLES = 'CLEAN_ROLES' as const
  export type CleanRoles = AE<typeof CLEAN_ROLES>;

  export const SET_ROLES_FILTER = 'SET_ROLES_FILTER' as const
  export type SetRolesFilter = A<typeof SET_ROLES_FILTER,
  {
    name?: string
    city_id?: number
    department?: string
    group_name?: string
  }>;


  //rolegroups
  export const SET_ROLE_GROUPS = 'SET_ROLE_GROUPS ' as const
  export type SetRoleGroups = A<typeof SET_ROLE_GROUPS , APITYPES.Doers.RoleGroups.GET.Resp>;


  //doer roles
  export const SET_CURRENT_DOER_ROLES = 'SET_CURRENT_DOER_ROLES' as const
  export type SetCurrentDoerRoles = A<typeof SET_CURRENT_DOER_ROLES,
  APITYPES.Doers.DoerRole.GET.Resp>;

  //news
  export const SET_NEWS = 'SET_NEWS' as const
  export type SetNews = A<typeof SET_NEWS,
  { news: APITYPES.News.GET.Resp, offset: number | undefined }>;

  export const SET_CURRENT_NEWS = 'SET_CURRENT_NEWS' as const
  export type SetCurrentNews = A<typeof SET_CURRENT_NEWS, APITYPES.News>;

  //users
  export const SET_USER_SUBSCRIPTIONS = 'SET_USER_SUBSCRIPTIONS' as const
  export type SetUserSubscriptions = A<typeof SET_USER_SUBSCRIPTIONS,
  APITYPES.UserSubscription[]>;

  export const SET_USER_ORDERS = 'SET_USER_ORDERS' as const
  export type SetUserOrders = A<typeof SET_USER_ORDERS, APITYPES.UserOrder[]>;

  // operations
  export const SET_OPERATIONS = 'SET_OPERATIONS' as const
  export type SetOperations = A<typeof SET_OPERATIONS,
  {
    operations: APITYPES.Operations.GET.Resp
    hasMoreOperations: boolean
  }>;

  export const CLEAR_OPERATIONS = 'CLEAR_OPERATIONS' as const
  export type ClearOperations = AE<typeof CLEAR_OPERATIONS>;

  export const SET_PARENT_OPERATION = 'SET_PARENT_OPERATION' as const
  export type SetParentOperation = A<typeof SET_PARENT_OPERATION,
  APITYPES.Operations.GetOperation.Resp>;

  export const SET_CHILD_OPERATION = 'SET_CHILD_OPERATION' as const
  export type SetChildOperation = A<typeof SET_CHILD_OPERATION,
  APITYPES.Operations.GetOperation.Resp>;

  export const CLEAR_OPERATION = 'CLEAR_OPERATION' as const
  export type ClearOperation = AE<typeof CLEAR_OPERATION>;

  export const SET_OPERATIONS_FILTER = 'SET_OPERATIONS_FILTER' as const
  export type SetOperationsFilter = A<typeof SET_OPERATIONS_FILTER,
  APITYPES.OperationsFilter>;

  export const SET_OPERATION_TASKS = 'SET_OPERATION_TASKS' as const
  export type SetOperationTasks = A<typeof SET_OPERATION_TASKS,
  APITYPES.Operations.GetOperationTasks.Resp>;

  export const CLEAR_OPERATION_TASKS = 'CLEAR_OPERATION_TASKS' as const
  export type ClearOperationTasks = AE<typeof CLEAR_OPERATION_TASKS>;

  export const SET_OPERATION_LOCATIONS_SEARCH =
    'SET_OPERATION_LOCATIONS_SEARCH' as const
  export type SetOperationLocationsSearch = A<typeof SET_OPERATION_LOCATIONS_SEARCH,
  APITYPES.Shifts.ShiftLocations.GET.Resp>;

  export const SET_OPERATION_FILES = 'SET_OPERATION_FILES' as const
  export type SetOperationFiles = A<typeof SET_OPERATION_FILES,
  APITYPES.Operations.GetOperationFiles.Resp>;

  export const SET_OPERATION_SYNC = 'SET_OPERATION_SYNC' as const
  export type SetOperationSync = A<typeof SET_OPERATION_SYNC,
  APITYPES.Operations.GetOperationsSyncState.Resp>;


  // tasks
  export const SET_TASKS = 'SET_TASKS' as const
  export type SetTasks = A<typeof SET_TASKS, APITYPES.Tasks.GET.Resp>;

  // export const SET_ALL_TASKS = 'SET_ALL_TASKS' as const
  // export type SetAllTasks = A<typeof SET_ALL_TASKS, APITYPES.Tasks.GET.Resp>

  // rent
  export const SET_RENT_DATA = 'SET_RENT_DATA' as const
  export type SetRentData = A<typeof SET_RENT_DATA, APITYPES.Rent.POST.Resp>;

  // products
  export const SET_PRODUCTS_FILTER = 'SET_PRODUCTS_FILTER' as const
  export type SetProductsFilter = A<typeof SET_PRODUCTS_FILTER,
  APITYPES.ProductsFilter>;

  export const SET_PRODUCTS = 'SET_PRODUCTS' as const
  export type SetProducts = A<typeof SET_PRODUCTS, APITYPES.Products.GET.Resp>;

  export const SET_PRODUCT = 'SET_PRODUCT' as const
  export type SetProduct = A<typeof SET_PRODUCT, APITYPES.Products.POST.Resp>;

  export const SET_ALL_PRODUCT_OPTIONS = 'SET_ALL_PRODUCT_OPTIONS' as const
  export type SetAllProductOptions = A<typeof SET_ALL_PRODUCT_OPTIONS,
  APITYPES.Products.GET.Resp>;

  export const SET_PRODUCT_GROUPS = 'SET_PRODUCT_GROUPS' as const
  export type SetProductGroups = A<typeof SET_PRODUCT_GROUPS,
  APITYPES.Products.GROUP.GET.Resp>;

  // customer tariffs
  export const SET_CUSTOMER_TARIFFS_FILTER =
    'SET_CUSTOMER_TARIFFS_FILTER' as const
  export type SetCustomerTariffsFilter = A<typeof SET_CUSTOMER_TARIFFS_FILTER,
  APITYPES.CustomerTariffFilter>;

  export const SET_CUSTOMER_TARIFFS = 'SET_CUSTOMER_TARIFFS' as const
  export type SetCustomerTariffs = A<typeof SET_CUSTOMER_TARIFFS,
  APITYPES.Tariffs.GET.Resp>;

  export const SET_CUSTOMER_TARIFF = 'SET_CUSTOMER_TARIFF' as const
  export type SetCustomerTariff = A<typeof SET_CUSTOMER_TARIFF,
  APITYPES.Tariffs.GetById.Resp>;

  export const SET_CUSTOMER_TARIFFS_PRODUCTS =
    'SET_CUSTOMER_TARIFFS_PRODUCTS' as const
  export type SetCustomerTariffsProducts = A<typeof SET_CUSTOMER_TARIFFS_PRODUCTS,
  APITYPES.Tariffs.Product.GET.Resp>;

  export const SET_CUSTOMER_TARIFFS_LOCATIONS =
    'SET_CUSTOMER_TARIFFS_LOCATIONS' as const
  export type SetCustomerTariffsLocations = A<typeof SET_CUSTOMER_TARIFFS_LOCATIONS,
  APITYPES.Tariffs.Location.GET.Resp>;

  // doer tariffs
  export const SET_DOER_TARIFFS = 'SET_DOER_TARIFFS' as const
  export type SetDoerTariffs = A<typeof SET_DOER_TARIFFS,
  APITYPES.DoerTariff.GET.Resp>;

  export const SET_DOER_TARIFFS_GROUPS = 'SET_DOER_TARIFFS_GROUPS' as const
  export type SetDoerTariffsGroups = A<typeof SET_DOER_TARIFFS_GROUPS,
  APITYPES.DoerTariff.Groups.GET.Resp>;

  export const CLEAN_DOER_TARIFFS_GROUPS  = 'CLEAN_DOER_TARIFFS_GROUPS ' as const
  export type CleanDoerTariffsGroups = AE<typeof CLEAN_DOER_TARIFFS_GROUPS>;

  export const SET_DOER_TARIFFS_FILTER = 'SET_DOER_TARIFFS_FILTER' as const
  export type SetDoerTariffsFilter = A<typeof SET_DOER_TARIFFS_FILTER,
  APITYPES.DoerTariffFilter>;

  export const SET_DOER_TARIFF = 'SET_DOER_TARIFF' as const
  export type SetDoerTariff = A<typeof SET_DOER_TARIFF,
  APITYPES.DoerTariff.GetById.Resp>;

  export const SET_DOER_TARIFF_TASKS = 'SET_DOER_TARIFF_TASKS' as const
  export type SetDoerTariffTasks = A<typeof SET_DOER_TARIFF_TASKS,
  APITYPES.DoerTariff.GetById.Resp['tasks']>;

  export const SET_DOER_TARIFF_SHIFT_TYPES = 'SET_DOER_TARIFF_SHIFT_TYPES' as const
  export type SetDoerTariffShiftTypes = A<typeof SET_DOER_TARIFF_SHIFT_TYPES,
  APITYPES.DoerTariff.ShiftTypes.GET.Resp>;

  export const SET_DOER_TARIFF_FUNCTIONS = 'SET_DOER_TARIFF_FUNCTIONS' as const
  export type SetDoerTariffFunctions = A<typeof SET_DOER_TARIFF_FUNCTIONS,
  APITYPES.DoerTariff.Functions.GET.Resp>;

  export const SET_TARIFFS_SYNC = 'SET_TARIFFS_SYNC' as const
  export type SetTariffsSync = A<typeof SET_TARIFFS_SYNC,
  APITYPES.DoerTariff.GetTariffSyncStateV2.Resp>;

  export const SET_BILL_CALCULATIONS = 'SET_BILL_CALCULATIONS' as const
  export type SetBillCalculations = A<typeof SET_BILL_CALCULATIONS,
  {billCalculations: APITYPES.DoerTariff.GetBillCalculations.Resp, offset: number}>;
  export const SET_SAVED_BILL_CALCULATION = 'SET_LAST_BILL_CALCULATION' as const
  export type SetSavedBillCalculation = A<typeof SET_SAVED_BILL_CALCULATION,{
    savedBillCalculation:APITYPES.DoerTariff.GetBillCalculations.Bill }>

  export const CLEAN_BILL_CALCULATIONS  = 'CLEAN_BILL_CALCULATIONS ' as const
  export type CleanBillCalculations = AE<typeof CLEAN_BILL_CALCULATIONS>;

  export const SET_MOTIVATION_STATISTICS = 'SET_MOTIVATION_STATISTICS' as const
  export type SetMotivationStatistics = A<typeof SET_MOTIVATION_STATISTICS, {
    motivationStatistics: APITYPES.DoerTariff.MotivationStatistics.Resp
    hasMore: boolean
  }>

  export const CLEAN_MOTIVATION_STATISTICS = 'CLEAN_MOTIVATION_STATISTICS' as const
  export type CleanMotivationStatistics = AE<typeof CLEAN_MOTIVATION_STATISTICS>

  export const SET_MOTIVATION_STATISTICS_FILTER = 'SET_MOTIVATION_STATISTICS_FILTER' as const
  // eslint-disable-next-line max-len
  export type SetMotivationStatisticsFilter = A<typeof SET_MOTIVATION_STATISTICS_FILTER, APITYPES.DoerTariff.MotivationStatistics.Req>

  // fleets
  export const SET_FLEETS = 'SET_FLEETS' as const
  export type SetFleets = A<typeof SET_FLEETS, APITYPES.Fleets.GET.Resp>

  export const SET_FLEETS_CARS = 'SET_FLEETS_CARS' as const
  export type SetFleetsCars = A<typeof SET_FLEETS_CARS, APITYPES.Fleets.GetCars.Resp>

  export const CLEAR_FLEETS_CARS = 'CLEAR_FLEETS_CARS' as const
  export type ClearFleetsCars = AE<typeof CLEAR_FLEETS_CARS>

  export const SET_FLEET_CAR = 'SET_FLEET_CAR' as const
  export type SetFleetCar = A<typeof SET_FLEET_CAR, APITYPES.Fleets.GetById.Resp>

  export const SET_FLEET_CAR_FILTER = 'SET_FLEET_CAR_FILTER' as const
  export type SetFleetCarFilter = A<typeof SET_FLEET_CAR_FILTER, APITYPES.FleetCarFilter>

  export const SET_FLEETS_SERVICES = 'SET_FLEETS_SERVICES' as const
  export type SetFleetsServices = A<typeof SET_FLEETS_SERVICES, APITYPES.Fleets.GetServices.Resp>

  export const CLEAR_FLEETS_SERVICES = 'CLEAR_FLEETS_SERVICES' as const
  export type ClearFleetsServices = AE<typeof CLEAR_FLEETS_SERVICES>

  export const SET_FLEET_SERVICES_FILTER = 'SET_FLEET_SERVICES_FILTER' as const
  export type SetFleetServicesFilter = A<typeof SET_FLEET_SERVICES_FILTER, APITYPES.FleetServicesFilter>

  // vacancies
  export const SET_DOER_VACANCIES = 'SET_DOER_VACANCIES' as const
  export type SetDoerVacancies = A<typeof SET_DOER_VACANCIES, {
    doerVacancies: APITYPES.DoerVacancies.Get.Resp
    hasMore: boolean
  }>

  export const CLEAN_DOER_VACANCIES = 'CLEAN_DOER_VACANCIES' as const
  export type CleanDoerVacancies = AE<typeof CLEAN_DOER_VACANCIES>

  export const SET_DOER_VACANCIES_FILTER = 'SET_DOER_VACANCIES_FILTER' as const
  export type SetDoerVacanciesFilter = A<typeof SET_DOER_VACANCIES_FILTER, APITYPES.DoerVacancies.Get.Query>

  export const SET_DOER_VACANCY = 'SET_DOER_VACANCY' as const
  export type SetDoerVacancy = A<typeof SET_DOER_VACANCY, APITYPES.DoerVacancies.GetVacancy.Resp>

  //motivations
  export const SET_DOER_MOTIVATIONS = 'SET_DOER_MOTIVATIONS' as const
  export type SetDoerMotivations = A<typeof SET_DOER_MOTIVATIONS, {
    doerMotivations: APITYPES.Performers.GetDoerMotivations.Resp[]
    hasMore: boolean
  }>

  export const CLEAN_DOER_MOTIVATIONS = 'CLEAN_DOER_MOTIVATIONS' as const
  export type CleanDoerMotivations = AE<typeof CLEAN_DOER_MOTIVATIONS>

  export const SET_DOER_PENALTY = 'SET_DOER_PENALTY' as const
  export type SetDoerPenalty = A<typeof SET_DOER_PENALTY, APITYPES.Performers.GetDoerPenalty.Resp>

  export const SET_DOER_COMPENSATION = 'SET_DOER_COMPENSATION' as const
  export type SetDoerCompensation = A<typeof SET_DOER_COMPENSATION, APITYPES.Performers.GetDoerCompensation.Resp>

  export const SET_DOER_MOTIVATIONS_FILTER = 'SET_DOER_MOTIVATIONS_FILTER' as const
  export type SetDoerMotivationsFilter = A<typeof SET_DOER_MOTIVATIONS_FILTER, APITYPES.Performers.GetDoerMotivations.Req>

  export const SET_PERFORMACE_PENALTIES = 'SET_PERFORMACE_PENALTIES' as const
  export type SetPerformersPenalties = A<typeof SET_PERFORMACE_PENALTIES, APITYPES.Performers.GetPenalties.Resp[]>

  export const ADD_MOTIVATIONS_FILTER_TAB = 'ADD_MOTIVATIONS_FILTER_TAB' as const
  export type AddMotivationsFilterTab = A<typeof ADD_MOTIVATIONS_FILTER_TAB, {
    title: string
    id: string
    motivationType: APITYPES.MOTIVATION_TYPE
  }>

  export const DELETE_MOTIVATIONS_FILTER_TAB = 'DELETE_MOTIVATIONS_FILTER_TAB' as const
  export type DeleteMotivationsFilterTab = A<typeof DELETE_MOTIVATIONS_FILTER_TAB, {id: string}>

  export const SET_ACTIVE_MOTIVATIONS_TAB = 'SET_ACTIVE_MOTIVATIONS_TAB' as const
  export type SetActiveMotivationsTab = A<typeof SET_ACTIVE_MOTIVATIONS_TAB, {id: string}>

  //mails
  export const SET_MAILING = 'SET_MAILING' as const
  export type SetMailing = A<typeof SET_MAILING, {
    mailing: Mailing[]
    hasMoreMailing: boolean
  }>

  export const CLEAN_MAILS = 'CLEAN_MAILS' as const
  export type CleanMails = AE<typeof CLEAN_MAILS>

  export const SET_MAILING_FILTER = 'SET_MAILING_FILTER' as const
  export type SetMailingFilter = A<typeof SET_MAILING_FILTER, APITYPES.MailingFilterType>

  export const ADD_MAILING = 'ADD_MAILING' as const
  export type AddMailing = A<typeof ADD_MAILING, APITYPES.Mailing.POST.Resp>

  export const SET_ALL_DOCUMENTS = 'SET_ALL_DOCUMENTS' as const
  export type SetAllDocuments = A<typeof SET_ALL_DOCUMENTS, APITYPES.Billing.GetBillDownloadUrl.Resp>

  export const CLEAN_ALL_DOCUMENTS = 'CLEAN_ALL_DOCUMENTS' as const
  export type CleanAllDocuments = AE<typeof CLEAN_ALL_DOCUMENTS>

  //yandexpro
  export const SET_YANDEXPRO_REPORTS = 'SET_YANDEXPRO_REPORTS' as const
  export type SetYandexProReports = A<typeof SET_YANDEXPRO_REPORTS, APITYPES.YandexPro.Reports.GET.Resp>
  export const SET_YANDEXPRO_REPORT = 'SET_YANDEXPRO_REPORT' as const
  export type SetYandexProReport = A<typeof SET_YANDEXPRO_REPORT, APITYPES.YandexPro.Reports.GetReportById.Resp>
  export const DEPLOY_YANDEXPRO_REPORT = 'DEPLOY_YANDEXPRO_REPORT' as const
  export type DeployYandexProReport = A<typeof DEPLOY_YANDEXPRO_REPORT, APITYPES.YandexPro.Reports.DeployReport.Resp>
  export const CONNECT_YANDEXPRO_EXECUTOR_AND_DOER = 'CONNECT_YANDEXPRO_EXECUTOR_AND_DOER' as const
  // eslint-disable-next-line max-len
  export type ConnectYandexProExecutorAndDoer = A<typeof CONNECT_YANDEXPRO_EXECUTOR_AND_DOER, APITYPES.YandexPro.Reports.Connect.Resp>
  export const UPDATE_YANDEXPRO_EXECUTOR_AND_DOER = 'UPDATE_YANDEXPRO_EXECUTOR_AND_DOER' as const
  // eslint-disable-next-line max-len
  export type updateYandexProExecutorAndDoer = A<typeof UPDATE_YANDEXPRO_EXECUTOR_AND_DOER, APITYPES.YandexPro.Reports.Connect.Resp>
  export const SET_YANDEXPRO_EXECUTOR = 'SET_YANDEXPRO_EXECUTOR' as const
  export type SetYandexPROExecutor = A<typeof SET_YANDEXPRO_EXECUTOR, APITYPES.YandexPro.GETExecutors.Resp>;

  export const CLEAR_YANDEXPRO_EXECUTOR = 'CLEAR_YANDEXPRO_EXECUTOR' as const
  export type ClearYandexProExecutor = AE<typeof CLEAR_YANDEXPRO_EXECUTOR>;

  export const CLEAR_YANDEXPRO_REPORT = 'CLEAR_YANDEXPRO_REPORT' as const
  export type ClearYandexPro = AE<typeof CLEAR_YANDEXPRO_REPORT>;

  export const RESET_YANDEX_PLACEMENTS = 'RESET_YANDEX_LOCATIONS' as const
  export type ResetYandexPlacements = AE<typeof RESET_YANDEX_PLACEMENTS>;

  export const SET_YANDEX_PLACEMENTS = 'SET_YANDEX_PLACEMENTS' as const
  export type SetYandexPlacements = A<typeof SET_YANDEX_PLACEMENTS, APITYPES.YandexPro.Placement[]>;

  export const UPDATE_YANDEX_PLACEMENTS = 'UPDATE_YANDEX_PLACEMENTS' as const
  export type UpdateYandexPlacements = A<typeof UPDATE_YANDEX_PLACEMENTS, Partial<PlacementToUpdate>>;

  export const DELETE_YANDEX_PLACEMENTS = 'DELETE_YANDEX_PLACEMENTS' as const
  export type DeleteYandexPlacements = A<typeof DELETE_YANDEX_PLACEMENTS,{id: string}>;


  export const RESET_YANDEX_INITIAL_PLACEMENTS = 'RESET_YANDEX_INITIAL_PLACEMENTS' as const
  export type ResetYandexInitialPlacements = AE<typeof RESET_YANDEX_INITIAL_PLACEMENTS>;

  export const SET_YANDEX_INITIAL_PLACEMENTS = 'SET_YANDEX_INITIAL_PLACEMENTS' as const
  export type SetYandexInitialPlacements = A<typeof SET_YANDEX_INITIAL_PLACEMENTS, APITYPES.YandexPro.PlacementInitial[]>;

  export const ADD_YANDEX_INITIAL_PLACEMENTS = 'ADD_YANDEX_INITIAL_PLACEMENTS' as const
  export type AddYandexInitialPlacements = A<typeof ADD_YANDEX_INITIAL_PLACEMENTS, APITYPES.YandexPro.PlacementInitial>;

  export const UPDATE_YANDEX_INITIAL_PLACEMENTS = 'UPDATE_YANDEX_INITIAL_PLACEMENTS' as const
  export type UpdateYandexInitialPlacements = A<typeof UPDATE_YANDEX_INITIAL_PLACEMENTS, Partial<PlacementToUpdate>>;

  export const DELETE_YANDEX_INITIAL_PLACEMENTS = 'DELETE_YANDEX_INITIAL_PLACEMENTS' as const
  export type DeleteYandexInitialPlacements = A<typeof DELETE_YANDEX_INITIAL_PLACEMENTS,{id: string}>;

  export const UPDATE_YANDEX_PLACEMENTS_MATCHED = 'UPDATE_YANDEX_PLACEMENTS_MATCHED' as const
  export type UpdateYandexPlacementsMatched = A<typeof UPDATE_YANDEX_PLACEMENTS_MATCHED, {isMatched: boolean, id: string}>;
  export const DELETE_YANDEX_EXECUTOR = 'DELETE_YANDEX_EXECUTOR' as const
  export type DeleteYandexExecutor = A<typeof DELETE_YANDEX_EXECUTOR, {id: string}>;

  //yandexLavka
  export const SET_YANDEX_LAVKA_REPORTS = 'SET_YANDEX_LAVKA_REPORTS' as const
  export type SetYandexLavkaReports = A<typeof SET_YANDEX_LAVKA_REPORTS, APITYPES.YandexLavka.Reports.GET.Resp>

  export const SET_YANDEX_LAVKA_UNMATCHED_EXECUTORS = 'SET_YANDEX_LAVKA_UNMATCHED_EXECUTORS' as const
  export type SetYandexLavkaUnmatchedExecutors = A<typeof SET_YANDEX_LAVKA_UNMATCHED_EXECUTORS,
  APITYPES.YandexLavka.UnmatchedExecutor[]>

  export const UPDATE_YANDEX_LAVKA_UNMATCHED_EXECUTOR = 'UPDATE_YANDEX_LAVKA_UNMATCHED_EXECUTOR' as const
  export type UpdateYandexLavkaUnmatchedExecutors = A<typeof UPDATE_YANDEX_LAVKA_UNMATCHED_EXECUTOR, {id: string}>


  export const SET_YANDEX_LAVKA_UNMATCHED_PLACEMENTS = 'SET_YANDEX_LAVKA_UNMATCHED_PLACEMENTS' as const
  export type SetYandexLavkaUnmatchedPlacements = A<typeof SET_YANDEX_LAVKA_UNMATCHED_PLACEMENTS,
  APITYPES.YandexLavka.UnmatchedPlacement[]>

  export const UPDATE_YANDEX_LAVKA_PLACEMENTS_MATCHED = 'UPDATE_YANDEX_LAVKA_PLACEMENTS_MATCHED' as const
  export type UpdateYandexLavkaPlacementsMatched = A<typeof UPDATE_YANDEX_LAVKA_PLACEMENTS_MATCHED, {id: string}>;

  export const DELETE_YANDEX_LAVKA_UNMATCHED_PLACEMENT = 'DELETE_YANDEX_LAVKA_UNMATCHED_PLACEMENT' as const
  export type DeleteYandexLavkaUnmatchedPlacements = A<typeof DELETE_YANDEX_LAVKA_UNMATCHED_PLACEMENT, {id: string}>;

  export const SET_YANDEX_LAVKA_PLACEMENTS = 'SET_YANDEX_LAVKA_PLACEMENTS' as const
  export type SetYandexLavkaPlacements = A<typeof SET_YANDEX_LAVKA_PLACEMENTS, APITYPES.YandexLavka.Placement[]>;

  export const ADD_YANDEX_LAVKA_PLACEMENTS = 'ADD_YANDEX_LAVKA_PLACEMENTS' as const
  export type AddYandexLavkaPlacements = A<typeof ADD_YANDEX_LAVKA_PLACEMENTS, APITYPES.YandexLavka.Placement>;

  export const DELETE_YANDEX_LAVKA_PLACEMENT = 'DELETE_YANDEX_LAVKA_PLACEMENT' as const
  export type DeleteYandexLavkaPlacements = A<typeof DELETE_YANDEX_LAVKA_PLACEMENT, {id: string}>;

  //DoerRecords
  export const ADD_DOER_RECORD_FILTER_TAB = 'ADD_DOER_RECORD_FILTER_TAB' as const
  export type AddDoerRecordFilterTab = A<typeof ADD_DOER_RECORD_FILTER_TAB, {title: string, filter: DoerRecordsFilter}>;

  export const DELETE_DOER_RECORD_FILTER_TAB = 'DELETE_DOER_RECORD_FILTER_TAB' as const
  export type DeleteDoerRecordFilterTab = A<typeof DELETE_DOER_RECORD_FILTER_TAB, {id: string}>;

  export const SET_ACTIVE_DOER_RECORDS_TAB = 'SET_ACTIVE_DOER_RECORDS_TAB' as const
  export type SetActiveDoerRecordsTab = A<typeof SET_ACTIVE_DOER_RECORDS_TAB, {id: string}>;

  export const SET_DOER_RECORDS_FILTER = 'SET_DOER_RECORDS_FILTER' as const
  export type SetDoerRecordsFilter = A<typeof SET_DOER_RECORDS_FILTER, { id: string, filter: DoerRecordsFilter }>;

  export const SET_DOER_RECORD_TYPES = 'SET_DOER_RECORD_TYPES' as const
  export type SetDoerRecordTypes= A<typeof SET_DOER_RECORD_TYPES, string[]>;


  //urent
  export const SET_URENT_REPORTS = 'SET_URENT_REPORTS' as const
  export type SetUrentReports = A<typeof SET_URENT_REPORTS, APITYPES.Urent.Reports.GET.Resp>

  export const CLEAR_URENT_REPORT = 'CLEAR_URENT_REPORT' as const
  export type ClearUrentReport = AE<typeof CLEAR_URENT_REPORT>

  export const SET_URENT_REPORT = 'SET_URENT_REPORT' as const
  export type SetUrentReport = A<typeof SET_URENT_REPORT, APITYPES.Urent.Report>

  export const UPDATE_URENT_UNMATCHED_EXECUTOR = 'UPDATE_URENT_UNMATCHED_EXECUTOR' as const
  export type UpdateUrentUnmatchedExecutors = A<typeof UPDATE_URENT_UNMATCHED_EXECUTOR, {id: string}>

  export const UPDATE_URENT_PLACEMENTS_MATCHED = 'UPDATE_URENT_PLACEMENTS_MATCHED' as const
  export type UpdateUrentPlacementsMatched = A<typeof UPDATE_URENT_PLACEMENTS_MATCHED, {id: string}>;

  export const DELETE_URENT_PLACEMENT = 'DELETE_URENT_PLACEMENT' as const
  export type DeleteUrentPlacements = A<typeof DELETE_URENT_PLACEMENT, {id: string}>;

  export const SET_URENT_PLACEMENTS = 'SET_URENT_PLACEMENTS' as const
  export type SetUrentPlacments = A<typeof SET_URENT_PLACEMENTS, APITYPES.Urent.Placement[]>;

  export const ADD_URENT_PLACEMENT = 'ADD_URENT_PLACEMENT' as const
  export type AddUrentPlacment = A<typeof ADD_URENT_PLACEMENT, APITYPES.Urent.Placement>;

  export const DELETE_URENT_EXECUTOR = 'DELETE_URENT_EXECUTOR' as const
  export type DeleteUrentExecutor = A<typeof DELETE_URENT_EXECUTOR, {id: string}>;

  export const SET_URENT_EXECUTOR = 'SET_URENT_EXECUTOR' as const
  export type SetUrentExecutor = A<typeof SET_URENT_EXECUTOR, APITYPES.Urent.Executor[]>;

  export const ADD_URENT_EXECUTOR = 'ADD_URENT_EXECUTOR' as const
  export type AddUrentExecutor = A<typeof ADD_URENT_EXECUTOR, APITYPES.Urent.Executor>;


  //internships
  export const SET_INTERNSHIPS = 'SET_INTERNSHIPS' as const
  export type SetInternships = A<typeof SET_INTERNSHIPS, { internships: APITYPES.Internships.Internship[], total: number}>

  export const CANCEL_INTERNSHIPS = 'CANCEL_INTERNSHIPS' as const
  export type CancelInternships = A<typeof CANCEL_INTERNSHIPS, {id: string}>;

  export const SET_INTERNSHIPS_FILTER = 'SET_INTERNSHIPS_FILTER' as const
  export type SetInternshipsFilter = A<typeof SET_INTERNSHIPS_FILTER, InternshipsFilters>

  export const SET_INTERNSHIPS_DOER_SEARCH = 'SET_INTERNSHIPS_DOER_SEARCH' as const
  export type SetInternshipsDoerSearch = A<typeof SET_INTERNSHIPS_DOER_SEARCH, boolean>

  export const CHANGE_INTERNSHIP_DOERS_STATE = 'CHANGE_INTERNSHIP_DOERS_STATE' as const
  export type ChangeInternshipDoersState = A<typeof CHANGE_INTERNSHIP_DOERS_STATE, {
    internshipId: string
    doerShiftId  : string
    status: APITYPES.Internships.STATE_TYPE
  }>

  export const SET_SIGNER_DICTIONARY = 'SET_SIGNER_DICTIONARY' as const
  export type SetSignerDictionary = A<typeof SET_SIGNER_DICTIONARY, APITYPES.Dictionary.Dictionary[]>;
}

export type Action =
  | Actions.SetIsUserSignedIn
  // | Actions.SetAllTasks
  | Actions.ClearFleetsCars
  | Actions.SetFleetCarFilter
  | Actions.SetFleetCar
  | Actions.SetFleetsCars
  | Actions.SetFleets
  | Actions.SetFleetsServices
  | Actions.SetFleetServicesFilter
  | Actions.ClearFleetsServices
  | Actions.SetDoerTariffFunctions
  | Actions.SetDoerContractors
  | Actions.SetPartnerContractors
  | Actions.SetBillsDoersFilter
  | Actions.SetExportBills
  | Actions.CleanBillsDoers
  | Actions.CleanExportBills
  | Actions.SetBillsDoers
  | Actions.SetDoerTariffTasks
  | Actions.SetDoerTariffShiftTypes
  | Actions.SetDoerTariffsFilter
  | Actions.SetDoerTariff
  | Actions.SetDoerTariffs
  | Actions.SetDoerTariffsGroups
  | Actions.CleanDoerTariffsGroups
  | Actions.SetSearchedCarwashes
  | Actions.SetCustomerTariff
  | Actions.SetProductGroups
  | Actions.SetAllProductOptions
  | Actions.SetCustomerTariffsFilter
  | Actions.SetCustomerTariffsLocations
  | Actions.SetCustomerTariffsProducts
  | Actions.SetCustomerTariffs
  | Actions.SetProductsFilter
  | Actions.SetProduct
  | Actions.SetProducts
  | Actions.ClearDoerShifts
  | Actions.ClearDoerReserves
  | Actions.SetDoerShifts
  | Actions.SetDoerReserves
  | Actions.SetDoer
  | Actions.SetTasks
  | Actions.SetAllPartners
  | Actions.SetStaticShiftLocations
  | Actions.AddStaticShiftLocations
  | Actions.SetLocationChildren
  | Actions.SetLocationPhotos
  | Actions.SetSearchedByIdDoer
  | Actions.SetLocationsFilter
  | Actions.SetRentData
  | Actions.SetShiftsForRedirect
  | Actions.SetOperationFiles
  | Actions.SetShiftOffers
  | Actions.SetSearchedDoer
  | Actions.SetOperationLocationsSearch
  | Actions.SetShiftDoersFilter
  | Actions.SetReserveOffers
  | Actions.ClearOperationTasks
  | Actions.SetOperationTasks
  | Actions.SetOperationsFilter
  | Actions.ClearOperation
  | Actions.SetParentOperation
  | Actions.SetChildOperation
  | Actions.ClearOperations
  | Actions.ClearOperationsStatistics
  | Actions.SetOperations
  | Actions.SetOffer
  | Actions.ClearOffers
  | Actions.SetOfferReserves
  | Actions.SetOffersFilter
  | Actions.SetOffers
  | Actions.AddShiftTab
  | Actions.RemoveShiftTab
  | Actions.SetUserSubscriptions
  | Actions.SetUserOrders
  | Actions.SetNews
  | Actions.SetCurrentNews
  | Actions.SetShiftTypesByRoleFilter
  | Actions.SetShiftTypesByRoleIdFilter
  | Actions.SetShiftTypesByRole
  | Actions.SetShiftTypesByRoleId
  | Actions.SetCurrentDoerRoles
  | Actions.SetRoles
  | Actions.CleanRoles
  | Actions.SetRoleGroups
  | Actions.SetShiftDoers
  | Actions.SetShiftTypes
  | Actions.SetEmployers
  | Actions.SetEmployer
  | Actions.SetShifts
  | Actions.SetShiftLocations
  | Actions.AddShiftLocation
  | Actions.ChangeRequestStatus
  | Actions.SetPartners
  | Actions.UpdatePartner
  | Actions.AddPartner
  | Actions.SetCarwashes
  | Actions.UpdateCarwash
  | Actions.AddCarwash
  | Actions.SetPartnerByInnInfo
  | Actions.SetPartner
  | Actions.SetCarwash
  | Actions.SetTariffs
  | Actions.CreateTariff
  | Actions.UpdateTariff
  | Actions.DeleteTariff
  | Actions.SetClientTypes
  | Actions.SetCarClasses
  | Actions.SetServices
  | Actions.CleanTariffs
  | Actions.SetTariffError
  | Actions.SetPartnerByInnForCreating
  | Actions.CleanPartners
  | Actions.SetIsCarwashCreating
  | Actions.SetPartnerIdForCreating
  | Actions.CleanCarwashes
  | Actions.SetDirectoryClientTypes
  | Actions.SetDirectoryServices
  | Actions.CreateDirectoryClientTypes
  | Actions.CreateDirectoryService
  | Actions.SetDirectoryClientType
  | Actions.AddCarClass
  | Actions.AddService
  | Actions.SetClientTypeCarClasses
  | Actions.SetClientTypeServices
  | Actions.SetDirectoryCarClasses
  | Actions.SetDirectoryCarClass
  | Actions.SetModels
  | Actions.SetBrands
  | Actions.SetBrand
  | Actions.SetCarClassCars
  | Actions.CreateDirectoryCarClasses
  | Actions.SetCarwashPhotos
  | Actions.SetCarwashesFilter
  | Actions.SetCarwashesSearch
  | Actions.SetPartnersSearch
  | Actions.UpdateClientTypeServices
  | Actions.UpdateClientTypeCarClasses
  | Actions.UpdateDirectoryServices
  | Actions.DeleteDirectoryService
  | Actions.SetCarwashesStatistics
  | Actions.SetPartnersStatistics
  | Actions.SetOperationsStatistics
  | Actions.DeleteDirectoryClientTypes
  | Actions.UpdateDirectoryClientTypes
  | Actions.SetDirectoryService
  | Actions.SetUsers
  | Actions.SetUser
  | Actions.SetUsersSearch
  | Actions.CleanUsers
  | Actions.UpdateClientTypes
  | Actions.SetPartnerDocuments
  | Actions.AddPartnerDocument
  | Actions.UpdatePartnerDocument
  | Actions.DeletePartnerDocument
  | Actions.SetSchedules
  | Actions.SetSchedule
  | Actions.DeleteSchedule
  | Actions.AddSchedule
  | Actions.AddScheduleInterval
  | Actions.UpdateScheduleInterval
  | Actions.DeleteScheduleInterval
  | Actions.SetScheduleIntervalsMap
  | Actions.SetDoers
  | Actions.UpdateDoer
  | Actions.CleanDoers
  | Actions.SetDoersFilter
  | Actions.SetBills
  | Actions.SetBill
  | Actions.CleanBills
  | Actions.SetContractors
  | Actions.SetContractor
  | Actions.CleanContractors
  | Actions.SetBillsFilter
  | Actions.SetContractorRecords
  | Actions.SetContractorsFilter
  | Actions.SetPenalties
  | Actions.SetPenaltyTypes
  | Actions.AddNewPenalty
  | Actions.UpdatePenaltyStatus
  | Actions.SetPenaltiesFilter
  | Actions.CleanPenalties
  | Actions.SetCompensations
  | Actions.SetCompensationTypes
  | Actions.addNewCompensation
  | Actions.SetCompensationsFilter
  | Actions.CleanCompensations
  | Actions.SetAllCarBrands
  | Actions.SetAllBrandModels
  | Actions.ClearBrandModels
  | Actions.SetCarModelInfo
  | Actions.SetCarModelCarClasses
  | Actions.SetShiftsFilter
  | Actions.DeleteShift
  | Actions.ClearShiftDoers
  | Actions.SetOperationSync
  | Actions.SetTariffsSync
  | Actions.SetMailing
  | Actions.CleanMails
  | Actions.AddMailing
  | Actions.SetMailingFilter
  | Actions.SetDoerMotivations
  | Actions.CleanDoerMotivations
  | Actions.SetDoerPenalty
  | Actions.SetDoerCompensation
  | Actions.SetDoerMotivationsFilter
  | Actions.SetPerformersPenalties
  | Actions.SetActiveMotivationsTab
  | Actions.DeleteMotivationsFilterTab
  | Actions.AddMotivationsFilterTab
  | Actions.SetAllDocuments
  | Actions.CleanAllDocuments
  | Actions.SetYandexProReports
  | Actions.SetYandexProReport
  | Actions.DeployYandexProReport
  | Actions.ConnectYandexProExecutorAndDoer
  | Actions.updateYandexProExecutorAndDoer
  | Actions.SetYandexPROExecutor
  | Actions.ClearYandexProExecutor
  | Actions.ClearYandexPro
  | Actions.ResetYandexPlacements
  | Actions.SetYandexPlacements
  | Actions.UpdateYandexPlacements
  | Actions.DeleteYandexPlacements
  | Actions.ResetYandexInitialPlacements
  | Actions.SetYandexInitialPlacements
  | Actions.AddYandexInitialPlacements
  | Actions.UpdateYandexInitialPlacements
  | Actions.DeleteYandexInitialPlacements
  | Actions.UpdateYandexPlacementsMatched
  | Actions.SetYandexLavkaReports // yandexLavka
  | Actions.SetYandexLavkaUnmatchedExecutors
  | Actions.UpdateYandexLavkaUnmatchedExecutors
  | Actions.SetYandexLavkaUnmatchedPlacements
  | Actions.UpdateYandexLavkaPlacementsMatched
  | Actions.DeleteYandexLavkaUnmatchedPlacements
  | Actions.SetYandexLavkaPlacements
  | Actions.AddYandexLavkaPlacements
  | Actions.DeleteYandexLavkaPlacements
  | Actions.SetDoersRecords
  | Actions.CleanDoersRecords
  | Actions.SetDoersRecordsPeriod
  | Actions.SetBillCalculations
  | Actions.CleanBillCalculations
  | Actions.SetMotivationStatistics
  | Actions.CleanMotivationStatistics
  | Actions.SetMotivationStatisticsFilter
  | Actions.SetRolesFilter
  | Actions.SetShiftOffersReverse
  | Actions.SetDoerMonitoringAllocations
  | Actions.SetDoerMonitoringAllocationsFilter
  | Actions.CleanDoerMonitoringAllocations
  | Actions.AddDoerRecordFilterTab
  | Actions.DeleteDoerRecordFilterTab
  | Actions.SetActiveDoerRecordsTab
  | Actions.SetDoerRecordsFilter
  | Actions.SetDoerRecordTypes
  | Actions.ResetDoersRecords
  | Actions.ClearDoerRecordsPeriod
  | Actions.SetSavedBillCalculation
  | Actions.SetDoerVacancies
  | Actions.CleanDoerVacancies
  | Actions.SetDoerVacanciesFilter
  | Actions.SetDoerVacancy
  | Actions.SetUrentReports
  | Actions.ClearUrentReport
  | Actions.SetUrentReport
  | Actions.UpdateUrentUnmatchedExecutors
  | Actions.UpdateUrentPlacementsMatched
  | Actions.DeleteUrentPlacements
  | Actions.SetUrentPlacments
  | Actions.AddUrentPlacment
  | Actions.SetUrentExecutor
  | Actions.DeleteUrentExecutor
  | Actions.AddUrentExecutor
  | Actions.DeleteYandexExecutor
  | Actions.SetInternships
  | Actions.CancelInternships
  | Actions.SetInternshipsFilter
  | Actions.SetInternshipsDoerSearch
  | Actions.ChangeInternshipDoersState
  | Actions.SetSignerDictionary

export const actionEmpty = <T>(type: T) => ({ type })
export const action = <T, D>(type: T, data: D) => ({
  type,
  data,
})

export type ThunkExtraArgument = {
  api: Api
};
export type ThAction<T = void> = ThunkAction<T,
State,
ThunkExtraArgument,
Action>;

export type SmartAction = Action | ThAction<Promise<any> | void>;
export type Dispatch = (a: SmartAction) => any;

export const useSmartDispatch = () => useDispatch<Dispatch>()
