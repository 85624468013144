import { APITYPES } from 'types/apitypes'
import { getEnumKeys } from 'utils/enumUtils'


export const getLocationsOptions = (locations: APITYPES.Location[] | null) => {
  if (!locations) {
    return []
  }
  const cities = getEnumKeys(APITYPES.Cities)

  return locations.map((location) => ({
    city_id: location.city_id,
    label: `${cities[location.city_id - 1]}, ${location.name}`,
    location: location,
    value: location.id,
  }))
}

export const getEmployerOptions = (employers: APITYPES.Employer[] | null) => {
  if (!employers?.length) {
    return []
  }

  return employers.map((employer) => ({
    label: employer.name,
    value: employer.id,
  }))
}

export const getTypesOptions = (
  cityId: number | null,
  shiftTypes: APITYPES.ShiftType[] | null,
  roleCityId?: number | undefined
) => {
  if (!shiftTypes) {
    return []
  }
  const shiftTypesArr = shiftTypes.map(({ city_id, name, id, begin, end , roles }) => ({
    city_id,
    label: (begin && end) ? `${name} (${formatTime(begin)} - ${formatTime(end)})` : name,
    roles,
    value: id,
  }))

  const id = cityId || roleCityId

  if (!id) {
    return shiftTypesArr
  }

  return shiftTypesArr.filter(
    (type) => type.city_id === id
  )
}

export const formatTime = (time: number) => {
  const [hours, minutes] = time.toString().split(':')

  return `${hours}:${minutes}`
}
