import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Modal, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchCarwashes } from 'AC/carwashes/carwashes'
import { addShiftLocation } from 'AC/shifts/shifts'
import { useDebounce } from 'utils/debounce'
import { getEnumKeysObject, getEnumOptions } from 'utils/enumUtils'
import { Loader } from 'components/Loader'
import { FormFooter } from 'components/FormFooter'

import { getLocationOptions, validateGeoLocation } from './utils'


const locationTypes = getEnumKeysObject(APITYPES.ShiftLocationsTypes)
const cities = getEnumOptions(APITYPES.Cities)

type Props = {
  isOpen: boolean
  onClose: () => void
}

const formItemLayout = {
  labelCol: { sm: { span: 4 } },
  wrapperCol: { sm: { span: 24 } },
}

const selector = (state: State) => ({
  carwashes: state.carwashesReducer.carwashes,
  isLoading: state.requestsReducer.fetchCarwashes === StatusRequest.LOADING,
  isCreating: state.requestsReducer.addShiftLocation === StatusRequest.LOADING,
})

export const AddLocationModal = (props: Props) => {
  const { isOpen, onClose } = props
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { carwashes, isLoading, isCreating } = useSelector(selector)
  const [locationType, setLocationType] = useState<string>('')
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearch = useDebounce(searchValue, 400)

  const onFinish = (values: any) => {
    let body: any = null

    if (!validateGeoLocation(form)) { return }

    if (values.type === locationTypes.carwash || values.type === locationTypes.box) {
      const carwash = carwashes.find((c) => c.id === values.typeItem)

      if (!carwash) {
        return
      }
      body = {
        type: values.type,
        name: carwash.address,
        carwash_id: carwash.id,
        city_id: values.city_id,
        latitude: values.latitude,
        longitude: values.longitude,
        description: values.description,
      }
    } else {
      body = {
        type: values.type,
        name: values.typeItem,
        city_id: values.city_id,
        latitude: values.latitude,
        longitude: values.longitude,
        description: values.description,
      }
    }

    if (!body) {return}

    dispatch(addShiftLocation(body, onClose))
  }

  const onTypeSelect = (type: string) => setLocationType(type)

  const onSearch = (value: string) => setSearchValue(value)

  useEffect(() => {
    dispatch(action(Actions.CLEAN_CARWASHES, {}))
  }, [])

  useEffect(() => {
    if (debouncedSearch) {
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
      dispatch(fetchCarwashes({search: debouncedSearch}, 10, 0))
    } else {
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
      dispatch(fetchCarwashes({}, 20))
    }
  }, [debouncedSearch])

  const afterClose = () => {
    setLocationType('')
    setSearchValue('')
    form.resetFields()
  }

  const onScroll = (e: any) => {
    const elem = e.target

    if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
      dispatch(fetchCarwashes({}, 20))
    }
  }

  return (
    <Modal
      title='Создание локации'
      visible={isOpen}
      onCancel={onClose}
      width={620}
      destroyOnClose
      footer={false}
      afterClose={afterClose}
    >
      <Form
        {...formItemLayout}
        form={form}
        onFinish={onFinish}
      >
        <Form.Item
          name='type'
          label='Тип'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          initialValue={locationType}
        >
          <Select
            onSelect={onTypeSelect}
            options={getLocationOptions()}
          />
        </Form.Item>

        <Form.Item
          name='city_id'
          label='Город'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          <Select options={cities} />
        </Form.Item>

        <Form.Item
          name='typeItem'
          label='Название'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
        >
          {
            locationType === locationTypes.carwash ||
          locationType === locationTypes.box ? (
                <Select
                  showSearch
                  placeholder={locationType && 'Введите адрес автомойки'}
                  disabled={!locationType}
                  onSearch={onSearch}
                  defaultActiveFirstOption={false}
                  notFoundContent={isLoading ? <Loader size='small' /> : undefined}
                  value={searchValue}
                  filterOption={
                    (input, option) =>
                    //@ts-ignore
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  loading={isLoading}
                  onPopupScroll={onScroll}
                >
                  {
                    carwashes.map((carwash) => (
                      <Select.Option
                        key={carwash.id}
                        value={carwash.id}
                      >
                        { carwash.address }
                      </Select.Option>
                    ))
                  }
                </Select>
              ) : (
                <Input
                  autoComplete='off'
                  placeholder={!locationType ? '' : 'Введите название локации'}
                  disabled={!locationType}
                />
              )
          }
        </Form.Item>

        <Form.Item
          name='description'
          label='Описание'
        >
          <Input.TextArea
            placeholder='Введите описание локации'
            maxLength={1000}
            autoSize={
              {
                minRows: 3,
                maxRows: 10,
              }
            }
          />
        </Form.Item>

        <Form.Item
          name='latitude'
          label='Широта'
          rules={[ { required: false } ]}
        >
          <InputNumber
            placeholder='0.000000'
            type='number'
            style={{ width: 280 }}
            step={0.000001}
          />
        </Form.Item>

        <Form.Item
          name='longitude'
          label='Долгота'
          rules={[ { required: false } ]}
        >
          <InputNumber
            placeholder='0.000000'
            type='number'
            style={{ width: 280 }}
            step={0.000001}
          />
        </Form.Item>

        <FormFooter
          isLoading={isCreating}
          onCancel={onClose}
        />
      </Form>
    </Modal>
  )
}
