import React from 'react'
import { Select, Typography } from 'antd'

import { apiV1 } from 'network/http'


const changeActiveStatus =  async (status:any, record: any) => {
  const newStatus = status === 'Активная' ? 'activate' : 'deactivate'

  try {
    // eslint-disable-next-line max-len
    await apiV1.put(`/v1/mailing/${record}/actions/${newStatus }`, {}, { headers: { 'Authorization': 'Bearer ' + localStorage.getItem('TOKEN') } })
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}

export const columns = ([
  {
    title: 'Название',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: 'Текст сообщения',
    dataIndex: 'text',
    key: 'text',
    align: 'center',
    width: 300,
  },
  {
    title: 'Создана',
    dataIndex: 'created',
    key: 'created',
    align: 'center',
  },
  {
    title: 'Время начала',
    dataIndex: 'start_time',
    key: 'start_time',
    align: 'center',
  },
  {
    title: 'Статус',
    dataIndex: 'state_name',
    key: 'state_name',
    align: 'center',
    render: (_: any, record: any) => {
      return (
        <>
          {
            record.mail.state_name === 'active' || record.mail.state_name == 'inactive' ?
              (
                <Select
                  style={{ width:'80%' }}
                  defaultValue={record.state_name}
                  onChange={(e) => changeActiveStatus(e,record.mail.id)}
                >
                  <Select.Option key='Дезактивировать'>Не активная</Select.Option>
                  <Select.Option key='Активная'>Активная</Select.Option>
                </Select>
              )
              :  <Typography>{ record.state_name }</Typography>
          }
        </>
      )
    },
  },
  {
    title: 'Количество получателей',
    dataIndex: 'recipients',
    key: 'recipients',
    align: 'center',
  },
])
export const recipientColumns = ([
  {
    title: 'Получатель',
    dataIndex: 'name',
    key: 'name',
    fixed: 'left',
  },
  {
    title: 'Статус',
    dataIndex: 'state_name',
    key: 'state_name',
    align: 'center',
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
  },

])

export const getRecipientColumns = () => recipientColumns
export const getColumns = () => columns
