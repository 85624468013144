import React, { useEffect, useState } from 'react'
import { Form, Input, InputNumber, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { APITYPES } from 'types/apitypes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { fetchCarwashes } from 'AC/carwashes/carwashes'
import { editShiftLocation } from 'AC/shifts/shifts'
import { useDebounce } from 'utils/debounce'
import { getEnumKeysObject } from 'utils/enumUtils'
import { Loader } from 'components/Loader'
import { FormFooter } from 'components/FormFooter'
import { filterEmptyValues } from '../../../../../../utils/urlUtils'
import { validateGeoLocation } from '../../AddLocationModal/utils'


const locationTypes = getEnumKeysObject(APITYPES.ShiftLocationsTypes)


type Props = {
  location: APITYPES.Location | null
  onClose: () => void
}

const formItemLayout = {
  labelCol: { sm: { span: 4 } },
  wrapperCol: { sm: { span: 24 } },
}

const selector = (state: State) => ({
  carwashes: state.carwashesReducer.carwashes,
  isLoading: state.requestsReducer.fetchCarwashes === StatusRequest.LOADING,
  isEditing: state.requestsReducer.editShiftLocation === StatusRequest.LOADING,
})

export const EditLocation = ({ location, onClose }: Props) => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { carwashes, isLoading, isEditing } = useSelector(selector)
  const [searchValue, setSearchValue] = useState<string>('')
  const debouncedSearch = useDebounce(searchValue, 400)

  const onFinish = (values: any) => {
    let body: any = null

    if (!validateGeoLocation(form)) { return }

    const carwash = carwashes.find((c) => c.id === values.typeItem)
    
    body = {
      name: values.typeItem,
      carwash_id: carwash?.id,
      latitude: values.latitude,
      longitude: values.longitude,
      description: values.description,
    }

    if (!body && !location?.id) {return}

    dispatch(
      editShiftLocation(
        location?.id as string,
        filterEmptyValues(body) as any,
        () => {
          onClose()
          setSearchValue('')
          form.resetFields()
        }
      )
    )
  }

  const onSearch = (value: string) => setSearchValue(value)

  useEffect(() => {
    dispatch(action(Actions.CLEAN_CARWASHES, {}))
  }, [])

  useEffect(() => {
    if (debouncedSearch) {
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
      dispatch(fetchCarwashes({ search: debouncedSearch }, 10, 0))
    } else {
      dispatch(action(Actions.CLEAN_CARWASHES, {}))
      dispatch(fetchCarwashes({}, 20))
    }
  }, [debouncedSearch])

  const onScroll = (e: any) => {
    const elem = e.target

    if (elem.scrollTop >= (elem.scrollHeight - elem.offsetHeight)) {
      dispatch(fetchCarwashes({}, 20))
    }
  }

  useEffect(() => {
    if (location) {
      const savedLocation: any = {
        description: location.description,
        latitude: parseFloat(String(location.latitude)),
        longitude: parseFloat(String(location.longitude)),
      }

      if (location.type === locationTypes.carwash || location.type === locationTypes.box) {
        const carwash = carwashes.find((c) => c.id === location.carwash_id)
        
        savedLocation.typeItem = carwash?.address
      } else {
        savedLocation.typeItem = location.name
      }

      form.setFieldsValue(savedLocation)
    }

    return () => {
      form.resetFields()
    }
  }, [form])

  return (
    <Form
      {...formItemLayout}
      form={form}
      onFinish={onFinish}
      style={{ padding: '24px 20px 20px 0' }}
    >
      <Form.Item
        name='typeItem'
        label='Название'
      >
        {
          location?.type === locationTypes.carwash ||
          location?.type === locationTypes.box ? (
            <Select
              showSearch
              placeholder={ location?.type && 'Выберите новый адрес автомойки' }
              disabled={ !location?.type }
              onSearch={ onSearch }
              defaultActiveFirstOption={ false }
              notFoundContent={ isLoading ? <Loader size="small"/> : undefined }
              value={ searchValue }
              filterOption={
                (input, option) =>
                  //@ts-ignore
                  option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={ isLoading }
              onPopupScroll={ onScroll }
            >
              {
                carwashes.map((carwash) => (
                  <Select.Option
                    key={ carwash.id }
                    value={ carwash.id }
                  >
                    { carwash.address }
                  </Select.Option>
                ))
              }
            </Select>
            ) : (
              <Input
                autoComplete='off'
                placeholder={!location?.type ? '' : 'Введите новое название локации'}
                disabled={!location?.type}
              />
            )
        }
      </Form.Item>

      <Form.Item
        name='description'
        label='Описание'
      >
        <Input.TextArea
          placeholder='Введите новое описание локации'
          maxLength={1000}
          autoSize={
            {
              minRows: 3,
              maxRows: 10,
            }
          }
        />
      </Form.Item>

      <Form.Item
        name='latitude'
        label='Широта'
        rules={[ { required: false } ]}
      >
        <InputNumber
          placeholder='0.000000'
          type='number'
          style={{ width: 280 }}
          step={0.000001}
        />
      </Form.Item>

      <Form.Item
        name='longitude'
        label='Долгота'
        rules={[ { required: false } ]}
      >
        <InputNumber
          placeholder='0.000000'
          type='number'
          style={{ width: 280 }}
          step={0.000001}
        />
      </Form.Item>

      <FormFooter
        okText='Подтвердить'
        isLoading={isEditing}
        onCancel={onClose}
      />
    </Form>
  )
}
