import { useLocation } from 'react-router-dom'


export const useQueryParams = () => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)

  const params: Record<string, string> = {}

  queryParams.forEach((value, key) => {
    params[key] = value
  })

  return params
}
