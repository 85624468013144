import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type MailingState = {
  mailing: APITYPES.MailingType[]
  hasMore: boolean
  mailingFilter: {
    state_name?: string
  }
}

export const initialState: MailingState = {
  mailing: [],
  hasMore: false,
  mailingFilter: { state_name: undefined },
}

export const mailingReducer: Reducer<MailingState, Action> = (
  state = initialState,
  a
): MailingState => {
  switch (a.type) {
    case Actions.CLEAN_MAILS: {
      return {
        ...state,
        mailing: [],
        hasMore: false,
      }
    }

    case Actions.SET_MAILING: {
      return {
        ...state,
        mailing: [...state.mailing, ...a.data.mailing],
        hasMore: a.data.hasMoreMailing,
      }
    }

    case Actions.SET_MAILING_FILTER: {
      return {
        ...state,
        mailingFilter: a.data,
      }
    }
  }

  return state
}
