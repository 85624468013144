import React from 'react'
import { Button, Popover } from 'antd'

import { APITYPES } from 'types/apitypes'

import { Partner } from './Partner'
import { Status } from './Status'

export const getColumns = () => {
  return (
    [
      {
        title: 'Адрес',
        dataIndex: 'address',
        key: 'address',
        fixed: 'left',
        width: 174,
      },
      {
        title: 'Регион',
        dataIndex: 'region',
        key: 'region',
        width: 152,
      },
      {
        title: 'Статус',
        dataIndex: 'state',
        key: 'state',
        width: 150,
        render: (value: APITYPES.Carwash) => (
          <Status
            value={value}
            key={value.id}
          />
        ),
      },
      {
        title: 'Партнер',
        dataIndex: 'partner',
        key: 'partner_id',
        width: 174,
        render: (value: APITYPES.Carwashes.Get.Resp[number]) => (
          <Partner
            value={value}
            key={value.id}
          />
        ),
      },
      {
        title: 'График работы',
        dataIndex: 'working_time',
        key: 'working_time',
        width: 150,
      },
      {
        title: 'Способ владения',
        dataIndex: 'own_type',
        key: 'own_type',
        width: 150,
      },
      {
        title: 'Формат сотрудничества',
        dataIndex: 'cooperation_type',
        key: 'cooperation_type',
        width: 150,
      },
      {
        title: 'Направления работы',
        dataIndex: 'client_type',
        key: 'client_type',
        width: 150,
        render: (value: APITYPES.Carwashes.Get.Resp[number]) => {
          const content = (
            <div>
              {value.client_types?.map((item, i) => (<p key={i}>{ item }</p>))}
            </div>
          )

          return (
            <Popover
              content={content}
              title={false}
              trigger='click'
            >
              <Button
                type='link'
                disabled={!value.client_types?.length}
                onClick={(e) => e.stopPropagation()}
              >
                Показать
              </Button>
            </Popover>
          )
        },
      },
    ]
  )
}

export const getRows = (carwashes: APITYPES.Carwashes.Get.Resp) => {
  const data = carwashes.map((carwash) => {
    const row: any = {}

    getColumns().map((column) => {
      if (column.dataIndex === 'state' || column.dataIndex === 'partner') {
        return
      }
      //@ts-ignore
      row[column.dataIndex] = carwash[column.dataIndex]
      row.key = carwash.id

      return
    })

    row.partner = carwash
    row.state = carwash
    row.client_type = carwash

    return row
  })

  return data
}