import { Form, Modal, Select } from 'antd'
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { State } from 'stores/rootReducer'
import { FormFooter } from 'components/FormFooter'
import { copyTariff } from 'AC/carwashes/copyTariff'
import { fetchCarwashes } from 'AC/carwashes/carwashes'


type Props = {
  isVisible: boolean
  onCancel: () => void
}

const selector = (state: State) => ({ carwashes: state.carwashesReducer.carwashes })

export const CopyForm = (props: Props) => {
  const { isVisible, onCancel } = props

  const { carwashes } = useSelector(selector)

  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(fetchCarwashes({ search: '' }, 10000, 0))
  },[dispatch])

  const onFinish = async (values: { carwash: string }) => {
    dispatch(copyTariff(values.carwash))

    form.resetFields()
    
    onCancel()
  }

  const [ form ] = useForm()

  return (
    <Modal
      title='Копирование тарифа'
      visible={isVisible}
      onCancel={onCancel}
      footer={false}
    >
      <Form
        name='copy_tariff'
        onFinish={onFinish}
        form={form}
      >
        <Form.Item
          name='carwash'
          rules={
            [{
              required: true,
              message: 'Поле должно быть заполнено',
            }]
          }
          label='Копировать с мойки'
        >
          <Select
            showSearch
            optionFilterProp='children'
            filterOption={
              (input, option: any) => {
                return option.children.toLocaleLowerCase().includes(input)
              }
            }
          >
            {
              carwashes.map((carwash)=>{
                return (
                  <Select.Option
                    key={carwash.id}
                    value={carwash.id}
                  >
                    { carwash.address }
                  </Select.Option>
                )
              })
            }
          </Select>
        </Form.Item>
        <FormFooter
          onCancel={onCancel}
          okText='Копировать'
          position='right'
        />
      </Form>
    </Modal>
  )
}