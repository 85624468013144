import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Select } from 'antd'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import qs from 'qs'

import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { isObjectEmpty } from 'utils/filterUtils'
import { getUrlSearchParams } from 'utils/urlUtils'
import { getMailing } from 'AC/doers/mailing'


const { Option } = Select

enum Status {
  'awaiting_sending' = 'Ожидает отправки',
  'sent' = 'Доставлено',
  'canceled' = 'Отменено',
  'active' = 'Активная'
}

const options = Object.entries(Status).map(([key, value]) => ({
  label: value,
  value: key,
}))

type Props = {
  isOpen: boolean
  onClose: () => void
}

export const Filters = ({ isOpen, onClose }: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = getUrlSearchParams()
  const [value, setValue] = useState<string | undefined>()

  useEffect(() => {
    if (!isObjectEmpty(params)) {
      const parsed = qs.parse(params) as {state_name: string}

      setValue(parsed?.state_name)
    }
  }, [params])

  const onFilterChange = (partial: {state_name: string }) => {
    setValue(partial['state_name'])

    history.push(ROUTES.DOERS.MAILING.PARAMS.createPath(partial))
    dispatch(action(Actions.CLEAN_MAILS, {}))
    dispatch(action(Actions.SET_MAILING_FILTER, partial))
    dispatch(getMailing(partial))
  }

  const onFilterReset = () => {
    dispatch(action(Actions.CLEAN_MAILS, {}))
    dispatch(action(Actions.SET_MAILING_FILTER, {}))
    history.push(ROUTES.DOERS.MAILING.path)
    dispatch(getMailing())
  }

  return (
    <Drawer
      title='Фильтры'
      placement='right'
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout='vertical'>
        <Form.Item label='Статус'>
          <Select
            allowClear
            value={value}
            onChange={(value) => onFilterChange({ state_name: value })}
          >
            {
              options.map((status) => (
                <Option
                  key={status.value}
                  value={status.value}
                >
                  { status.label }
                </Option>
              ))
            }
          </Select>
        </Form.Item>

        <Button onClick={onFilterReset}>Сбросить фильтры</Button>
      </Form>
    </Drawer>
  )
}
