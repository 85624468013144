import { action, Actions, ThAction } from 'actions'
import { showError } from 'components/modals/ErrorModal'
import { openNotification } from 'components/modals/OperationNotification'
import { StatusRequest } from 'stores/requestsReducer'
import { APITYPES } from 'types/apitypes'
import { changeRequestState } from '../request'


export type FetchPartnersParamsType = {
  search?: string
  state?: string
  offset?: number
  limit?: number
  all?: boolean
}
export const fetchPartners = (params: FetchPartnersParamsType = {
  offset: 0,
  all: false,
}): ThAction => {
  const { search, state, offset, limit, all  } = params


  return async (dispatch, getState, { api }) => {
    dispatch(changeRequestState('fetchPartners', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Partners.Get.URL, {
        params: {
          search,
          offset: offset || getState().partnersReducer.partners.length,
          limit: limit || 15,
          state,
        },
      })

      if (resp) {
        if (all) {
          dispatch(action(Actions.SET_ALL_PARTNERS, resp))
        } else {
          dispatch(action(Actions.SET_PARTNERS, resp))
        }

        dispatch(changeRequestState('fetchPartners', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchPartners', StatusRequest.ERROR))

      throw(e)
    }
  }
}

export const updatePartner = (values: APITYPES.PartnerBase, id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('updatePartner', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Partners.Put.URL, values, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_PARTNER, {
          partner: resp,
          id,
        }))

        openNotification('Сохранено')

        dispatch(changeRequestState('updatePartner', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('updatePartner', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const offPartner = (partner_id: string, reason: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('offPartner', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Partners.Off.URL, { reason }, { replaceUrl: { partner_id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_PARTNER, {
          partner: resp,
          id: partner_id,
        }))

        dispatch(changeRequestState('offPartner', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('offPartner', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const activatePartner = (partner_id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('activatePartner', StatusRequest.LOADING))

    try {
      const resp = await api.put(APITYPES.Partners.Activate.URL, {} ,{ replaceUrl: { partner_id } })

      if (resp) {
        dispatch(action(Actions.UPDATE_PARTNER, {
          partner: resp,
          id: partner_id,
        }))

        dispatch(changeRequestState('activatePartner', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('activatePartner', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const createPartner = (values: APITYPES.PartnerBase): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('createPartner', StatusRequest.LOADING))

    try {
      const resp = await api.post(APITYPES.Partners.Post.URL, values)

      if (resp) {
        await dispatch(action(Actions.CLEAN_PARTNERS, {}))
        dispatch(fetchPartners({ limit: 10 }))

        dispatch(changeRequestState('createPartner', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('createPartner', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchPartnerByInn = (inn: number): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchPartnerByInn', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Dadata.Party.URL, { params: { inn: String(inn) } })

      if (resp) {
        dispatch(action(Actions.SET_PARTNER_BY_INN_INFO, resp))

        dispatch(changeRequestState('fetchPartnerByInn', StatusRequest.LOADED))
      }
    } catch (e: any) {
      dispatch(changeRequestState('fetchPartnerByInn', StatusRequest.ERROR))

      showError(e.response.data)

      throw(e)
    }
  }
}

export const fetchPartner = (id: string): ThAction => {
  return async (dispatch, _, { api }) => {
    dispatch(changeRequestState('fetchPartner', StatusRequest.LOADING))

    try {
      const resp = await api.get(APITYPES.Partners.GetPartner.URL, { replaceUrl: { id } })

      if (resp) {
        dispatch(action(Actions.SET_PARTNER, resp))

        dispatch(changeRequestState('fetchPartner', StatusRequest.LOADED))
      }
    } catch (e) {
      dispatch(changeRequestState('fetchPartner', StatusRequest.ERROR))

      throw(e)
    }
  }
}