import React from 'react'
import { Form, Input, Checkbox, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { updateCarwash } from 'AC/carwashes/carwashes'
import { APITYPES } from 'types/apitypes'
import { AREAS, DISTRICTS, SUBWAYS } from '../../config'

import styles from './styles.module.sass'


type Props = {
  carwash: APITYPES.Carwashes.GetCarwash.Resp
}

export const Location = (props: Props) => {
  const dispatch = useDispatch()

  const [form] = useForm()

  const { carwash } = props

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const onFinish = async (values: any) => {
    dispatch(updateCarwash({
      // ...carwash,
      // rent_cost: (values.rent_cost && values.rent_unit) ? +values.rent_cost : undefined,
      // rent_unit: (values.rent_cost && values.rent_unit) ? values.rent_unit : undefined,
      location: {
        region: values.region || null,
        area: values.area || null,
        district: values.district || null,
        address: values.address || null,
        subway: values.subway || null,
        directions: values.directions || null,
        longitude: String(values.longitude),
        latitude: String(values.latitude),
        located: values.located || null,
        closed_area: Boolean(values.closed_area),
      },
    }, carwash.id))
  }

  const onBlur = () => {
    onFinish(form.getFieldsValue())
  }

  return (
    <Form
      className={styles.form}
      form={form}
      name='location'
      {...formItemLayout}
      onFinish={onFinish}
      onBlur={onBlur}
    >
      <Form.Item
        className={styles.filed}
        name='region'
        label='Регион'
        initialValue={carwash.location.region}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Select showSearch>
          <Select.Option value='Москва'>Москва</Select.Option>
          <Select.Option value='Санкт-Петербург'>Санкт-Петербург</Select.Option>
          <Select.Option value='Сочи'>Сочи</Select.Option>
          <Select.Option value='Краснодар'>Краснодар</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='area'
        label='Округ'
        initialValue={carwash.location.area}
      >
        <Select showSearch>
          {
            AREAS.map((area, i)=>{
              return (
                <Select.Option
                  key={i}
                  value={area}
                >
                  { area }
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='district'
        label='Район'
        initialValue={carwash.location.district}
      >
        <Select showSearch>
          {
            DISTRICTS.map((item, i)=>{
              return (
                <Select.Option
                  key={i}
                  value={item}
                >
                  { item }
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='address'
        label='Адрес'
        initialValue={carwash.location.address}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='subway'
        label='Метро'
        initialValue={carwash.location.subway}
      >
        <Select showSearch>
          {
            SUBWAYS.map((item, i)=>{
              return (
                <Select.Option
                  key={i}
                  value={item}
                >
                  { item }
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='directions'
        label='Как проехать'
        initialValue={carwash.location.directions}
      >
        <Input />
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='latitude'
        label='Широта'
        initialValue={parseFloat(String(carwash.location.latitude))}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Input
          type='number'
          step={0.000001}
        />
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='longitude'
        label='Долгота'
        initialValue={parseFloat(String(carwash.location.longitude))}
        rules={
          [{
            required: true,
            message: 'Поле должно быть заполнено',
          }]
        }
      >
        <Input
          type='number'
          step={0.000001}
        />
      </Form.Item>
      <Form.Item
        className={styles.filed}
        initialValue={carwash.location.closed_area}
        name='closed_area'
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
      >
        <Checkbox>На закрытой территории (есть шлагбаум)</Checkbox>
      </Form.Item>
      <Form.Item
        className={styles.filed}
        name='located'
        label='Расположение мойки'
        initialValue={carwash.location.located}
      >
        <Select showSearch>
          <Select.Option value='Отдельностоящая'>Отдельностоящая</Select.Option>
          <Select.Option value='В паркинге'>В паркинге</Select.Option>
        </Select>
      </Form.Item>
    </Form>
  )
}