import React, { FC, useEffect, useState } from 'react'
import { Button, DatePicker, Form, Input, Select } from 'antd'
import moment from 'moment/moment'
import { Moment } from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { BaseOptionType } from 'antd/lib/select'
import classNames from 'classnames'
import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import momentTimezone from 'moment-timezone'

import { fullWidth, required } from 'utils/formUtils'
import { FormFooter } from 'components/FormFooter'
import { backDateFormat, dateFormat, dateTimezoneFormat } from 'utils/dateUtils'
import { filterSelectionOptions } from 'utils/filterUtils'
import {
  getEmployerOptions,
  getLocationsOptions,
  getTypesOptions,
} from '../../../ShiftsPage/components/AddShiftModal/CreateForm/utils'
import { State } from 'stores/rootReducer'
import { fetchEmployers, fetchLocations, fetchShiftTypes } from 'AC/shifts/shifts'
import { APITYPES } from 'types/apitypes'
import { addInternship } from 'AC/internships/internships'
import { getRolesOptions } from 'utils/select-options'

import cls from './create-internship-form.module.sass'


type Location = APITYPES.Location;


type Roles = {
  id: string
  places: number
}

type FormValues = {
  date: [moment.Moment, moment.Moment]
  shift_type_id: string
  location_id: string
  roles: Roles[]
  employer_id: string
  time: moment.Moment
};

const formLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 19 },
}
const formItemLayout = { wrapperCol: { span: 24 } }


const datePickerShowTime = {
  showHour: true,
  showMinute: true,
  showSecond: false,
}

export type AddInternshipModalPropsType = {
  onClose: () => void
}

const selector = (state: State) => ({
  shiftTypes: state.shiftsReducer.shiftTypes,
  locations: state.shiftsReducer.locations,
  roles: state.shiftsReducer.shiftTypes,
  employers: state.shiftsReducer.employers,
})

export const CreateInternshipForm: FC<AddInternshipModalPropsType> = ({ onClose }) => {
  const { shiftTypes, locations, employers } = useSelector(selector)
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(null)
  const [roles, setRoles] = useState<APITYPES.ShiftTypeRole[]>([])
  const [state, setState] = useState<string[]>([])

  const [form] = Form.useForm<FormValues>()
  const dispatch = useDispatch()
  const disabledDate = (date: Moment) => date.isBefore(moment(), 'minute')

  const onLocationChange = (id: string, option?: BaseOptionType) => {
    setSelectedLocation(option?.location || null)
  }

  const onShiftTypeChange = (id: string, option?: BaseOptionType) => {
    setRoles(option?.roles || [])
  }

  const onRolesChange = () => {
    const fields = form.getFieldValue('roles')

    setState(fields?.filter((f: Roles) => f?.id).map((f: Roles) => f.id) || [])
  }

  const onFinish = (values: FormValues) => {
    const {
      date,
      shift_type_id,
      location_id,
      roles,
      employer_id,
      time,
    } = values


    const timezone = localStorage.getItem('timezone')

    const [startDate, endDate] = date

    const body: APITYPES.Internships.ReqCreate = {
      date_from: startDate.format(backDateFormat),
      date_to: endDate.format(backDateFormat),
      shift_type_id,
      location_id,
      roles: roles.map((r) => ({
        id: r.id,
        places: r.places,
      })),
      is_intership: true,
      employer_id,
      time: timezone ? momentTimezone(time)
        .tz(timezone, true)
        .format(dateTimezoneFormat) : time?.format(dateTimezoneFormat),
      method: 'create',
    }

    dispatch(addInternship(body, onClose))
  }

  useEffect(() => {
    if (!employers?.length) {
      dispatch(fetchEmployers())
    }

    if (!locations?.length) {
      dispatch(fetchLocations())
    }

    if (!shiftTypes?.length) {
      dispatch(fetchShiftTypes())
    }
  }, [])

  return (

    <Form
      {...formLayout}
      form={form}
      onFinish={onFinish}
    >
      <Form.Item
        name='date'
        label='Период'
        rules={required}
      >
        <DatePicker.RangePicker
          format={dateFormat}
          style={fullWidth}
        />
      </Form.Item>

      <Form.Item
        label='Отложенная публикация'
        name='time'
      >
        <DatePicker
          format='LLL'
          style={fullWidth}
          showTime={datePickerShowTime}
          minuteStep={30}
          disabledDate={disabledDate}
        />
      </Form.Item>

      <Form.Item
        name='employer_id'
        label='Заказчик'
        rules={required}
      >
        <Select
          allowClear
          showSearch
          options={getEmployerOptions(employers)}
          filterOption={filterSelectionOptions}
        />
      </Form.Item>

      <Form.Item
        name='location_id'
        label='Локация'
        rules={required}
      >
        <Select
          allowClear
          showSearch
          options={getLocationsOptions(locations)}
          filterOption={filterSelectionOptions}
          onChange={onLocationChange}
        />
      </Form.Item>

      <Form.Item
        name='shift_type_id'
        label='Время стажировки'
        rules={required}
      >
        <Select
          allowClear
          showSearch
          disabled={!selectedLocation}
          options={selectedLocation?.city_id ? getTypesOptions(selectedLocation.city_id, shiftTypes) : []}
          filterOption={filterSelectionOptions}
          onChange={onShiftTypeChange}
        />
      </Form.Item>
      <Form.List
        name='roles'
        initialValue={
          [
            {
              id: undefined,
              places: undefined,
            },
          ]
        }
      >
        {
          (fields, { add, remove }) => (
            <>
              <div className={cls.roleContainer}>
                {
                  fields.map(({ key, name, ...restField }) => (
                    <div
                      className={cls.roleRow}
                      key={key}
                    >
                      <Form.Item
                        {...restField}
                        {...formItemLayout}

                        className={classNames(cls.flexGrow, cls.formItem)}
                        name={[name, 'id']}
                        rules={required}
                      >
                        <Select
                          style={fullWidth}
                          allowClear
                          showSearch
                          disabled={!selectedLocation}
                          filterOption={filterSelectionOptions}
                          options={
                            getRolesOptions(roles).map((role) => ({
                              ...role,
                              disabled: state.includes(role.value),
                            }))
                          }
                          onChange={
                            (value) => {
                              if (value) {
                                setState([...state, value])
                              } else {
                                onRolesChange()
                              }
                            }
                          }
                          placeholder='Выберите роль'
                        />
                      </Form.Item>

                      <Form.Item
                        {...restField}
                        {...formItemLayout}
                        className={cls.formItem}
                        name={[name, 'places']}
                        rules={required}
                      >
                        <Input
                          style={fullWidth}
                          type='number'
                          placeholder='Кол-во мест'
                        />
                      </Form.Item>
                      <Button
                        disabled={key === 0}
                        type='ghost'
                        icon={<CloseOutlined />}
                        onClick={
                          () => {
                            remove(name)
                            onRolesChange()
                          }
                        }
                      />
                    </div>
                  ))
                }
              </div>

              <Button
                type='dashed'
                onClick={add}
                block
                icon={<PlusOutlined />}
              >
                Добавить роль
              </Button>
            </>
          )
        }
      </Form.List>

      <FormFooter
        className={cls.footer}
        onCancel={onClose}
        isLoading={false}
      />
    </Form>
  )
}
