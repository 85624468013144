import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Button, Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import { StatusRequest } from 'stores/requestsReducer'
import { State } from 'stores/rootReducer'
import { fetchUsers } from 'AC/users/getUsers'
import { Loader } from 'components/Loader'
import { getMailing } from 'AC/doers/mailing'
import { filterEmptyValues, getUrlSearchParams } from 'utils/urlUtils'
import { isObjectEmpty } from 'utils/filterUtils'
import useIntersectionObserver from 'utils/hooks/useIntersectionObserver'
import { action, Actions } from 'actions'
import { FilterIcon } from 'components/FilterIcon'
import { getColumns, getRecipientColumns } from './utils/getColumns'
import { getRecipients , getRows } from './utils/getRows'

import styles from './styles.module.sass'
import { AddModal, Filters } from './components'


const selector = (state: State) => {
  const { mailing } = state.mailingReducer

  const isMailsLoading = state.requestsReducer.getMailing === StatusRequest.LOADING
  const isFetchMailsLoading = state.requestsReducer.getMailing === StatusRequest.LOADING
  const isMailsUpdating = state.requestsReducer.getMailing === StatusRequest.LOADING
  const isMailsTableLoading = isMailsLoading || isMailsUpdating || isFetchMailsLoading
  const { hasMore } = state.mailingReducer
  const filter = state.mailingReducer.mailingFilter
  
  return {
    mailing,
    isMailsTableLoading,
    hasMore,
    filter,
  }
}

export const Mailing = () => {
  const dispatch = useDispatch()
  const { isMailsTableLoading, mailing, hasMore, filter } = useSelector(selector)
  const params = useMemo(() => getUrlSearchParams(), [])
  const lastRowRef = useRef(null)
  const entry = useIntersectionObserver(lastRowRef, {})

  const [isFilterOpen, setFilterOpen] = useState(false)
  const toggleFilters = () => setFilterOpen((prev) => !prev)
  const [addModal, setAddModal] = useState(false)
  const toggleAddModal = () => setAddModal(!addModal)
    
  useEffect(() => {
    const newParams = filterEmptyValues(params)

    dispatch(action(Actions.CLEAN_MAILS, {}))
    dispatch(getMailing(newParams))

    dispatch(action(Actions.SET_MAILING_FILTER, { ...newParams }))
    dispatch(fetchUsers())
  }, [dispatch, params])

  useEffect(() => {
    if (entry?.isIntersecting && !isMailsTableLoading && hasMore) {
      const newParams = filterEmptyValues(params)
      const newFilter = filterEmptyValues(filter)

      dispatch(getMailing({
        ...newFilter,
        ...newParams,
      }))
    }
  }, [dispatch, entry?.isIntersecting])

  if (!mailing) {
    return <Loader />
  }
  
  return (
    <>
      <div className={styles.container}>
        <div className={styles.toolbar}>
          <Button
            type='primary'
            onClick={toggleAddModal}
          >
            Добавить рассылку
          </Button>
          <Button
            icon={<FilterIcon isFilterEmpty={isObjectEmpty(filter)} />}
            size='small'
            onClick={toggleFilters}
          />
        </div>

        <Table
          sticky
          size='small'
          loading={isMailsTableLoading}
          columns={getColumns() as any}
          dataSource={getRows(mailing || [])}
          className={styles.table}
          bordered
          pagination={false}
          onRow={
            (record, index ) => ({
              onClick: () => void 0,
              ref: index === mailing?.length - 1 ? lastRowRef : undefined,
            })
          }
          expandable={
            {
              expandedRowRender: (group) => {
                const mail = mailing.find((mail) => mail.id === group.key)
                const recipients = getRecipients(mail)

                return (
                  <Table
                    key={group.key}
                    bordered
                    columns={getRecipientColumns() as any}
                    className={styles.table}
                    // @ts-ignore
                    dataSource={recipients}
                    size='small'
                    pagination={false}
                    // onRow={(record) => ({ onClick: () => setItem(record.data) })}
                  />
                )
              },
            }
          }
        />
      </div>
      <Filters
        isOpen={isFilterOpen}
        onClose={toggleFilters}
      />

      <AddModal
        isOpen={addModal}
        onClose={toggleAddModal}
      />
    </>
  )
}
