import React from 'react'
import { Form, Input, Checkbox, Select } from 'antd'
import { useDispatch } from 'react-redux'
import { useForm } from 'antd/lib/form/Form'

import { updateCarwash } from 'AC/carwashes/carwashes'
import { APITYPES } from 'types/apitypes'
import { qosDictionary } from '../../config'

import styles from './styles.module.sass'


type Props = {
  carwash: APITYPES.Carwashes.GetCarwash.Resp
}

export const Properties = (props: Props) => {
  const dispatch = useDispatch()

  const { carwash } = props

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  }

  const onFinish = async (values: any) => {
    dispatch(updateCarwash({
      qos: parseFloat(values.qos),
      object_type: values.object_type || null,
      layout: values.layout || null,
      space_quantity: (parseFloat(values.space_quantity) || parseFloat(values.space_quantity) === 0) ?
        parseFloat(values.space_quantity) : null,
      gate_height: (parseFloat(values.gate_height) || parseFloat(values.gate_height) === 0) ?
        parseFloat(values.gate_height) : null,
      tire_service: Boolean(values.tire_service),
      chemical_cleaning: Boolean(values.chemical_cleaning),
      client_zone: Boolean(values.client_zone),
      vending_machine: Boolean(values.vending_machine),
      cameras: Boolean(values.cameras),
      cashless_payment: Boolean(values.cashless_payment),
      fuel_card: Boolean(values.fuel_card),
      parking: Boolean(values.parking),
    }, carwash.id))
  }

  const [form] = useForm()

  const onBlur = () => {
    onFinish(form.getFieldsValue())
  }

  return (
    <Form
      className={styles.form}
      name='time_related_controls'
      {...formItemLayout}
      onFinish={onFinish}
      onBlur={onBlur}
      form={form}
    >
      <Form.Item
        className={styles.field}
        name='object_type'
        label='Тип объекта'
        initialValue={carwash.object_type ?? 'Мойка'}
        rules={[{
            required: true,
            message: 'Поле должно быть заполнено',
          }]}
      >
        <Select showSearch>
          <Select.Option value='Мойка'>Мойка</Select.Option>
          <Select.Option value='Шиномонтаж'>Шиномонтаж</Select.Option>
          <Select.Option value='Автосервис'>Автосервис</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='layout'
        label='Планировка'
        initialValue={carwash.layout ?? 'Боксы'}
        rules={[{
            required: true,
            message: 'Поле должно быть заполнено',
          }]}
      >
        <Select showSearch>
          <Select.Option value='Боксы'>Боксы</Select.Option>
          <Select.Option value='Туннельная'>Туннельная</Select.Option>
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='space_quantity'
        label='Кол-во боксов (машиномест для туннеля)'
        initialValue={carwash.space_quantity}
      >
        <Input type='number' />
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='gate_height'
        label='Высота ворот, м'
        initialValue={carwash.gate_height}
      >
        <Input type='number' />
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='qos'
        label='Качество обслуживания'
        initialValue={carwash.qos === undefined ? carwash.qos : carwash.qos || '25'}
      >
        <Select>
          {
            Array.from(qosDictionary.keys()).map((key)=>{
              return (
                <Select.Option
                  key={key}
                  value={key}
                >
                  { qosDictionary.get(key) }
                </Select.Option>
              )
            })
          }
        </Select>
      </Form.Item>
      <Form.Item
        className={styles.field}
        initialValue={Boolean(carwash.tire_service)}
        name='tire_service'
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
      >
        <Checkbox>Шиномонтаж</Checkbox>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='chemical_cleaning'
        initialValue={Boolean(carwash.chemical_cleaning)}
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
      >
        <Checkbox>Химчистка</Checkbox>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='client_zone'
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
        initialValue={Boolean(carwash.client_zone)}
      >
        <Checkbox>Клиентская зона</Checkbox>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='vending_machine'
        initialValue={Boolean(carwash.vending_machine)}
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
      >
        <Checkbox>Вендиногвый аппарат</Checkbox>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='cameras'
        initialValue={Boolean(carwash.cameras)}
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
      >
        <Checkbox>Камера контроля мойки автомобиля</Checkbox>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='cashless_payment'
        initialValue={Boolean(carwash.cashless_payment)}
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
      >
        <Checkbox>Безналичная оплсата услуг</Checkbox>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='fuel_card'
        initialValue={Boolean(carwash.fuel_card)}
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
      >
        <Checkbox>Работа с топливными картами</Checkbox>
      </Form.Item>
      <Form.Item
        className={styles.field}
        name='parking'
        initialValue={Boolean(carwash.parking)}
        valuePropName='checked'
        wrapperCol={
          {
            xs: {
              span: 24,
              offset: 0,
            },
            sm: {
              span: 16,
              offset: 8,
            },
          }
        }
      >
        <Checkbox>Наличие парковки</Checkbox>
      </Form.Item>
    </Form>
  )
}