enum Titles {
  main = 'Главная',
  partners = 'Партнеры: реестр',
  carwashes = 'Партнеры: мойки',
  rent = 'Партнеры: аренда',
  products = 'Маркетинг: продукты',
  product_groups = 'Маркетинг: группы',
  tariffs = 'Маркетинг: тарифы',
  users = 'Клиенты',
  doers = 'Исполнители: исполнители',
  offers = 'Исполнители: заявки',
  roles = 'Исполнители: роли',
  shifts = 'Исполнители: смены',
  operations = 'Исполнители: операции',
  locations = 'Исполнители: локации',
  doer_tariffs = 'Исполнители: тарифы',
  news = 'Исполнители: новости',
  yandexpro = 'Исполнители: яндексПро',
  penalties = 'Исполнители: штрафы',
  mailing = 'Исполнители: рассылки',
  bills = 'Биллинг: счета партнеры',
  contractors = 'Биллинг: контрагенты',
  billing_doers = 'Биллинг: счета исполнители',
  doer_records = 'Биллинг: расчеты исполнителей',
  client_types = 'Справочник: типы клиентов',
  car_classes = 'Справочник: классы автомобилей',
  cars = 'Справочник: автомобили',
  services = 'Справочник: услуги',
  fleets_cars = 'Флиты: автомобили',
  interships = 'Исполнители',
}

export const getTitle = (pathname: string) => {
  if (pathname === '/') {
    return Titles.main
  }

  const path = pathname.split('/').filter((el) => el)
  //@ts-ignore

  return Titles[path[0]]
}