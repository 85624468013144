import React, { useEffect } from 'react'
import { Table } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { StatusRequest } from 'stores/requestsReducer'
import { getTableYScroll } from 'utils/scrollUtils'

import { RentHeader } from './RentHeader'
import { getColumns, getRows } from './utils'
import styles from './styles.module.sass'

const selector = (state: State) => ({
  rent: state.billingReducer.rentData,
  isLoading: state.requestsReducer.fetchRentByPeriod === StatusRequest.LOADING,
})

export const Rent = () => {
  const dispatch = useDispatch()
  const {rent, isLoading} = useSelector(selector)

  // drop data when out
  useEffect(() => {
    return () => {
      dispatch(action(Actions.SET_RENT_DATA, null))
    }
  }, [])

  return (
    <div>
      <RentHeader hasBillingData={Boolean(rent)}/>

      <Table
        sticky
        bordered
        pagination={false}
        className={styles.table}
        loading={isLoading}
        columns={getColumns() as any}
        dataSource={getRows(rent || [])}
        scroll={{y: getTableYScroll(172)}}
        size={'small'}
      />
    </div>
  );
};