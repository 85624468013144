import React from 'react'
import { Button, Drawer, Form, Select } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { ROUTES } from 'config/routes'
import { action, Actions } from 'actions'
import { State } from 'stores/rootReducer'
import { APITYPES } from 'types/apitypes'
import { getTranslateCarwashStatus } from 'utils/tagUtils'
import { carwashStates, filterCities } from './utils'

const {Option} = Select

type Props = {
  isOpen: boolean
  onClose: () => void
}

const selector = (state: State) => ({
  filter: state.carwashesReducer.filter,
  clientTypes: state.directoryReducer.clientTypes,
  partners: state.partnersReducer.allPartners,
})

export const Filters = ({isOpen, onClose}: Props) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const {filter, partners, clientTypes} = useSelector(selector)

  const onFilterChange = (partial: Partial<APITYPES.CarwashFilter>) => {
    dispatch(action(Actions.CLEAN_CARWASHES, {}))
    dispatch(action(Actions.SET_CARWASHES_FILTER, {...filter, ...partial}))
    history.push(ROUTES.CARWASHES.PARAMS.createPath({...filter, ...partial}))
  }

  const resetFilters = () => {
    dispatch(action(Actions.SET_CARWASHES_FILTER, {}))
    history.push(ROUTES.CARWASHES.path)
    dispatch(action(Actions.CLEAN_CARWASHES, {}))
  }

  return (
    <Drawer
      title="Фильтры"
      onClose={onClose}
      visible={isOpen}
      forceRender
    >
      <Form layout="vertical">
        <Form.Item label="Регион">
          <Select
            allowClear
            placeholder="Выберите регион..."
            value={filter?.region}
            onChange={(value) => onFilterChange({region: value})}
          >
            {filterCities.map(city => <Option key={city} value={city}>{city}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label="Статус">
          <Select
            allowClear
            mode="multiple"
            placeholder="Выберите статус..."
            value={filter?.state}
            onChange={(value) => onFilterChange({state: value.length ? value : undefined})}
          >
            {carwashStates.map(state => <Option key={state} value={state}>{getTranslateCarwashStatus(state)}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label="Партнер">
          <Select
            allowClear
            showSearch
            placeholder="Выберите партнера..."
            value={filter?.partner_id}
            onChange={(value) => onFilterChange({partner_id: value})}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {partners.map(partner => <Option key={partner.id} value={partner.id}>{partner.legal_name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item label="Направление работы">
          <Select
            allowClear
            showSearch
            mode="multiple"
            placeholder="Выберите направление..."
            value={filter?.client_type}
            onChange={(value) => onFilterChange({client_type: value.length ? value : undefined})}
            filterOption={(input, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            {clientTypes?.map(type => <Option key={type.name} value={type.name}>{type.name}</Option>)}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button onClick={resetFilters}>
            Сбросить фильтры
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
};
