import { Reducer } from 'redux'

import { Action, Actions } from 'actions'
import { APITYPES } from 'types/apitypes'


export type CarwashesState = {
  schedules: APITYPES.Schedule[]
  schedule: APITYPES.Schedule | null
  scheduleIntervalsMap: Map<any, any> | null
  filter: APITYPES.CarwashFilter
  carwashes: APITYPES.Carwashes.Get.Resp
  carwash: APITYPES.Carwashes.GetCarwash.Resp | null
  tariffs: APITYPES.SupplierTariffService[] | null
  hasMore: boolean
  clientTypes: APITYPES.CarwashClientType[] | []
  carClasses: APITYPES.CarClasses.Get.Resp | []
  services: APITYPES.Services.Get.Resp | []
  partnerIdForCreating: string | null
  error: {
    serviceId: string
    carClassId: string
  } | null
  isCreating: boolean
  carwashPhotos?: APITYPES.Carwashes.GetPhotos.Resp
  carwashPenalties: APITYPES.Carwashes.GetPenalties.Resp | []
  penaltyTypes: APITYPES.Penalty.Get.Resp | []
  penaltyFilter: {
    state?: string[]
    begin?: string
    end?: string
  }
  searchedCarwashes: APITYPES.Carwashes.Get.Resp
  carwashCompensations: APITYPES.Carwashes.GetCompensations.Resp | []
  compensationTypes: APITYPES.Compensation.Get.Resp | []
  compensationFilter: {
    // state?: string[]
    begin?: string
    end?: string
  }
}

export const initialCarwashesState = {
  carwashes: [],
  schedules: [],
  filter: {},
  hasMore: true,
  carwash: null,
  schedule: null,
  scheduleIntervalsMap: null,
  tariffs: null,
  clientTypes: [],
  carClasses: [],
  services: [],
  error: null,
  isCreating: false,
  partnerIdForCreating: null,
  carwashPenalties: [],
  penaltyTypes: [],
  penaltyFilter: {},
  carwashCompensations: [],
  compensationTypes: [],
  compensationFilter: {},
  searchedCarwashes: [],
}

export const carwashesReducer: Reducer<CarwashesState, Action> = (state = initialCarwashesState, a): CarwashesState => {
  switch (a.type) {
    case Actions.SET_SEARCHED_CARWHASHES:
      return {
        ...state,
        searchedCarwashes: a.data,
      }

    case Actions.UPDATE_PENALTY_STATUS:
      return {
        ...state,
        carwashPenalties: [...state.carwashPenalties.map((item) => item.id === a.data.id ? a.data : item)],
      }

    case Actions.SET_PENALTIES_FILTER:
      return {
        ...state,
        penaltyFilter: a.data,
      }

    case Actions.CLEAN_PENALTIES:
      return {
        ...state,
        carwashPenalties: [],
      }

    case Actions.ADD_NEW_PENALTY:
      return {
        ...state,
        carwashPenalties: [a.data, ...state.carwashPenalties],
      }

    case Actions.SET_PENALTY_TYPES:
      return {
        ...state,
        penaltyTypes: a.data,
      }

    case Actions.SET_PENALTIES:
      return {
        ...state,
        carwashPenalties: a.data.carwashPenalties,
      }

    case Actions.SET_COMPENSATIONS_FILTER:
      return {
        ...state,
        compensationFilter: a.data,
      }
  
    case Actions.CLEAN_COMPENSATIONS:
      return {
        ...state,
        carwashCompensations: [],
      }
  
    case Actions.ADD_NEW_COMPENSATION:
      return {
        ...state,
        carwashCompensations: [a.data, ...state.carwashCompensations],
      }
  
    case Actions.SET_COMPENSATION_TYPES:
      return {
        ...state,
        compensationTypes: a.data,
      }
  
    case Actions.SET_COMPENSATIONS:
      return {
        ...state,
        carwashCompensations: a.data.carwashCompensations,
      }
    
    case Actions.SET_SCHEDULE_INTERVALS_MAP:
      return {
        ...state,
        scheduleIntervalsMap: a.data,
      }

    case Actions.ADD_SCHEDULE_INTERVAL:
      return {
        ...state,
        schedule: state.schedule ? {
          ...state.schedule,
          intervals: state.schedule.intervals ? [a.data, ...state.schedule.intervals] : [a.data],
        } : null,
      }

    case Actions.UPDATE_SCHEDULE_INTERVAL:
      return {
        ...state,
        schedule: state.schedule ? {
          ...state.schedule,
          intervals: state.schedule.intervals?.map((item)=>{
            if (item.id === a.data.id) {return a.data}

            return item
          }),
        } : null,
      }

    case Actions.DELETE_SCHEDULE_INTERVAL:
      return {
        ...state,
        schedule: state.schedule ? {
          ...state.schedule,
          intervals: state.schedule.intervals?.filter((item)=>item.id !== a.data),
        } : null,
      }

    case Actions.SET_SCHEDULES:
      return {
        ...state,
        schedules: a.data,
      }

    case Actions.SET_SCHEDULE:
      return {
        ...state,
        schedule: a.data,
      }

    case Actions.DELETE_SCHEDULE:
      return {
        ...state,
        schedules: state.schedules.filter((item)=>item.id !== a.data),
      }

    case Actions.ADD_SCHEDULE:
      return {
        ...state,
        schedules: [...state.schedules, a.data],
      }

    case Actions.SET_CARWASHES_SEARCH:
      return {
        ...state,
        filter: {
          ...state.filter,
          search: a.data,
        },
      }

    case Actions.SET_CARWASHES_FILTER:
      return {
        ...state,
        filter: a.data,
      }

    case Actions.SET_CARWASH_PHOTOS:
      return {
        ...state,
        carwashPhotos: a.data,
      }

    case Actions.CLEAN_CARWASHES:
      return {
        ...state,
        carwashes: [],
        hasMore: true,
      }

    case Actions.SET_PARTNER_ID_FOR_CREATING:
      return {
        ...state,
        partnerIdForCreating: a.data,
      }

    case Actions.SET_IS_CARWASH_CREATING:
      return {
        ...state,
        isCreating: a.data,
      }

    case Actions.SET_TARIFF_ERROR:
      return {
        ...state,
        error: a.data,
      }

    case Actions.CLEAN_TARIFFS:
      return {
        ...state,
        tariffs: null,
      }

    case Actions.SET_CLIENT_TYPES:
      return {
        ...state,
        clientTypes: a.data,
      }

    case Actions.UPDATE_CLIENT_TYPES:
      return {
        ...state,
        clientTypes: state.clientTypes.map((item: APITYPES.CarwashClientType)=>item.id === a.data.id ? {
          ...item,
          is_connected: a.data.value,
        } : item),
      }

    case Actions.SET_CAR_CLASSES:
      return {
        ...state,
        carClasses: a.data,
      }

    case Actions.SET_SERVICES:
      return {
        ...state,
        services: a.data,
      }

    case Actions.SET_TARIFFS:
      return {
        ...state,
        tariffs: a.data,
      }

    case Actions.UPDATE_TARIFF:
      return {
        ...state,
        tariffs: state.tariffs ? state.tariffs.map((tariff) => {
          if (a.data.supplier_tariff_service_id === tariff.supplier_tariff_service_id) { return a.data }

          return tariff
        }) : null,
      }

    case Actions.CREATE_TARIFF:
      return {
        ...state,
        tariffs: state.tariffs ? [a.data, ...state.tariffs] : [a.data],
      }

    case Actions.DELETE_TARIFF:
      return {
        ...state,
        tariffs: state.tariffs ? state.tariffs.filter((tariff)=>tariff.supplier_tariff_service_id !== a.data) : null,
      }

    case Actions.SET_CARWASHES:
      return {
        ...state,
        carwashes: a.data.offset === 0 ? a.data.carwashes : [...state.carwashes, ...a.data.carwashes],
        hasMore: a.data.carwashes.length ? true : false,
      }

    case Actions.SET_CARWASH:
      return {
        ...state,
        carwash: a.data,
      }

    case Actions.UPDATE_CARWASH:

      return {
        ...state,
        carwash: a.data.carwash,
        carwashes: state.carwashes.map((carwash) => {
          if (carwash.id === a.data.id) {
            return {
              id: a.data.carwash.id,
              name: a.data.carwash.name,
              qos: a.data.carwash.qos,
              region: a.data.carwash.location.region,
              address: a.data.carwash.location.address,
              state: a.data.carwash.state,
              bq_id: a.data.carwash.bq_id,
              partner_id: a.data.carwash.partner_id,
              object_photos: a.data.carwash.object_photos,
              own_type: a.data.carwash.own_type,
              cooperation_type: a.data.carwash.cooperation_type,
              questionnare: a.data.carwash.questionnare,
              object_type: a.data.carwash.object_type,
              layout: a.data.carwash.layout,
              space_quantity: a.data.carwash.space_quantity,
              tire_service: a.data.carwash.tire_service,
              chemical_cleaning: a.data.carwash.chemical_cleaning,
              client_zone: a.data.carwash.client_zone,
              vending_machine: a.data.carwash.vending_machine,
              cameras: a.data.carwash.cameras,
              cashless_payment: a.data.carwash.cashless_payment,
              fuel_card: a.data.carwash.fuel_card,
              parking: a.data.carwash.parking,
              gate_height: a.data.carwash.gate_height,
              max_load_time: a.data.carwash.max_load_time,
              working_time: a.data.carwash.working_time,
              rent_cost: a.data.carwash.rent_cost,
              rent_unit: a.data.carwash.rent_unit,
              cooperation_date: a.data.carwash.cooperation_date,
            }
          }

          return carwash
        }),
      }

    case Actions.ADD_CARWASH:
      return {
        ...state,
        carwashes: [...state.carwashes, {
          id: a.data.id,
          name: a.data.name,
          region: a.data.location.region,
          address: a.data.location.address,
          state: a.data.state,
          bq_id: a.data.bq_id,
          partner_id: a.data.partner_id,
          object_photos: a.data.object_photos,
          own_type: a.data.own_type,
          cooperation_type: a.data.cooperation_type,
          questionnare: a.data.questionnare,
          object_type: a.data.object_type,
          layout: a.data.layout,
          space_quantity: a.data.space_quantity,
          tire_service: a.data.tire_service,
          chemical_cleaning: a.data.chemical_cleaning,
          client_zone: a.data.client_zone,
          vending_machine: a.data.vending_machine,
          cameras: a.data.cameras,
          cashless_payment: a.data.cashless_payment,
          fuel_card: a.data.fuel_card,
          parking: a.data.parking,
          gate_height: a.data.gate_height,
          max_load_time: a.data.max_load_time,
          working_time: a.data.working_time,
          rent_cost: a.data.rent_cost,
          rent_unit: a.data.rent_unit,
          cooperation_date: a.data.cooperation_date,
        }],
      }
  }

  return state
}
